

































import { Component, Prop, Vue } from "vue-property-decorator";
import store from "../store/index";
import { Actions, Mutations } from "../store/StoreActions";
import { ApiModels } from "../ApiModels/DataModel";
@Component
export default class extends Vue {}
