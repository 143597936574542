














import { Component, Prop, Vue } from "vue-property-decorator";
import { Actions, Mutations } from "../store/StoreActions";
import { ApiModels } from "../ApiModels/DataModel";

@Component
export default class extends Vue {
  private demoProjects = new Array<ApiModels.DemoResponseType>();
  private unsubscribe: any;
  private messages = new Array<ApiModels.TestMessageType>();
  private batchIndex = 0;
  private headers = [
    { text: "Success", value: "success" },
    { text: "Step", value: "step" },
    { text: "Info", value: "info" }
  ];

  mounted() {
    this.$store.dispatch(Actions.LOAD_ALL_DEMOS);
  }

  processBatch() {
    if (this.batchIndex < this.demoProjects.length) {
      this.$store.dispatch(Actions.TEST_DEMO_PROJECTS, {
        url: this.demoProjects[this.batchIndex].url,
        name: this.demoProjects[this.batchIndex].name,
        externalId: this.demoProjects[this.batchIndex].externalId
      });
    }
  }

  testDemoProjects() {
    this.$store.commit(Mutations.CLEAR_TEST_MESSAGES);
    this.messages = [];
    this.batchIndex = 0;
    this.processBatch();
  }

  getDemoProjects() {
    this.demoProjects = this.$store.state.demoProjects;
  }

  created() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === Mutations.SET_DEMO_PROJECTS) {
        this.getDemoProjects();
      }
      if (mutation.type === Mutations.ADD_TEST_MESSAGES) {
        this.messages.push(mutation.payload);
        this.batchIndex++;
        this.processBatch();
      }
    });

    this.$store.dispatch(Actions.LOAD_DEMO);
  }

  beforeDestroy() {
    this.unsubscribe();
  }
}
