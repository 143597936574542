import Vue from "vue";
import VueRouter from "vue-router";
import ModelCreate from "../components/ModelCreate.vue";
import Projects from "../components/Projects.vue";
import CreateProject from "../components/ProjectCreate.vue";
import Workspace from "../components/Workspace.vue";
import Generate from "../components/Generate.vue";
import Auth from "../components/Auth.vue";
import Home from "../components/Home.vue";
import CreateJob from "../components/CreateJob.vue";
import MyJobs from "../components/MyJobs.vue";
import Support from "../components/Support.vue";
import CreateRoute from "../components/RouteCreate.vue";
import CreateQuery from "../components/QueryCreate.vue";
import RequestATool from "../components/RequestATool.vue";
import CreateRouteCollection from "../components/RouteCollectionCreate.vue";
import SimpleInputOutputComponent from "../components/SimpleInputOutputComponent.vue";
import JMESTool from "../components/Tools/JMESTool.vue";
import Test from "../components/Test.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/auth",
    name: "GithubCallback",
    component: Auth,
    props: (route: any) => ({ code: route.query.code })
  },
  {
    path: "/support",
    name: "Support",
    component: Support
  },
  {
    path: "/test",
    name: "Test",
    component: Test
  },
  {
    path: "/routecollections/create",
    name: "CreateRouteCollection",
    component: CreateRouteCollection
  },
  {
    path: "/routecollections/edit/:id",
    name: "EditRouteCollection",
    component: CreateRouteCollection,
    props: true
  },
  {
    path: "/routes/create",
    name: "CreateRoute",
    component: CreateRoute
  },
  {
    path: "/routes/edit/:id",
    name: "EditRoute",
    component: CreateRoute,
    props: true
  },
  {
    path: "/queries/create",
    name: "CreateQuery",
    component: CreateQuery
  },
  {
    path: "/queries/edit/:id",
    name: "EditQuery",
    component: CreateQuery,
    props: true
  },
  {
    path: "/projects",
    name: "Projects",
    component: Projects
  },
  {
    path: "/jobs/myjobs",
    name: "MyJobs",
    component: MyJobs
  },
  {
    path: "/jobs/create",
    name: "CreateJob",
    component: CreateJob
  },
  {
    path: "/projects/create",
    name: "Create",
    component: CreateProject
  },
  {
    path: "/projects/edit/:id",
    name: "Edit",
    component: CreateProject,
    props: true
  },
  {
    path: "/models/create",
    name: "ModelCreate",
    component: ModelCreate
  },
  {
    path: "/models/edit/:id",
    name: "ModelEdit",
    component: ModelCreate,
    props: true
  },
  {
    path: "/workspace",
    name: "Workspace",
    component: Workspace
  },
  {
    path: "/generate",
    name: "Generate",
    component: Generate
  },
  {
    path: "/tools/jmes",
    name: "JMESTool",
    component: JMESTool
  },
  {
    path: "/tools/base64encoder",
    name: "Base64Encoder",
    component: SimpleInputOutputComponent,
    props: {
      hint: "Any string",
      label: "Base 64 encode a string",
      route: "tools/base64/encode",
      seo: "A simple tool to base 64 encode strings."
    }
  },
  {
    path: "/tools/base64decoder",
    name: "Base64Decoder",
    component: SimpleInputOutputComponent,
    props: {
      hint: "Base64 encoded string",
      label: "Decode a base64 encoded string",
      route: "tools/base64/decode",
      seo: "A simple tool to base 64 decode strings."
    }
  },
  {
    path: "/tools/generateguid",
    name: "GenerateGuids",
    component: SimpleInputOutputComponent,
    props: {
      hint:
        "A number from to 1000. If no input it provided you will receive 1 guid.",
      label: "Guid generator",
      route: "tools/generateguid",
      seo: "Generate guids"
    }
  },
  {
    path: "/tools/formatters/csharp",
    name: "CSharpCodeFormatter",
    component: SimpleInputOutputComponent,
    props: {
      hint: "C# code",
      label: "C# code formatter",
      route: "tools/formatters/csharp",
      seo: "Format your valid C# code."
    }
  },
  {
    path: "/tools/formatters/json",
    name: "JSONFormatter",
    component: SimpleInputOutputComponent,
    props: {
      hint: "Valid JSON",
      label: "JSON Formatter",
      route: "tools/formatters/json",
      seo: "Format a valid JSON string."
    }
  },
  {
    path: "/tools/jwt/decode",
    name: "JWTDecoder",
    component: SimpleInputOutputComponent,
    props: {
      hint: "Encoded JWT",
      label: "JWT Decoder",
      route: "tools/jwt/decode",
      seo: "Decode a JWT."
    }
  },
  {
    path: "/tools/parse/json2csharpclass",
    name: "JSON2CSharpClass",
    component: SimpleInputOutputComponent,
    props: {
      hint: "JSON Object",
      label: "JSON to C# Class",
      initialValue:`{
        "id": 21,
        "version": "bf63cc45-1db8-4e01-b656-d0617671f292",
        "name": {
          "first": "John",
          "last": "Doe"
        },
        "attributes": [
          {
            "male": true,
            "identified": false,
            "found":"2007-12-01"
          }
        ]
      }`,
      route: "tools/parse/json2csharpclass",
      seo: "Parse JSON into a C# class"
    }
  },
  {
    path: "/tools/requestatool",
    name: "RequestATool",
    component: RequestATool
  }
];

const router = new VueRouter({
  routes,
  mode: "history"
});

export default router;
