






















































import { Component, Prop, Vue } from "vue-property-decorator";
import store from "../store/index";
import { Actions, Mutations, EventBusActions } from "../store/StoreActions";
@Component
export default class extends Vue {
  public props = {
    label: "",
    initialValue:"",
    hint: "",
    route: "",
    seo: ""
  };

  private label = this.$attrs.label;
  private seo = this.$attrs.seo;
  private hint = this.$attrs.hint;
  private input = this.$attrs.initialValue;
  private result = "";
  private route = this.$attrs.route;
  private loading = false;
  private unsubscribe: any;

  submit() {
    this.loading = true;
    this.$store.dispatch(Actions.EXECUTE_TOOL, {
      route: this.route,
      input: this.input
    });
  }

  copy(value: string) {
    this.$global.copyToClipoard(value);
  }

  created() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === Mutations.SET_EXECUTE_TOOL_RESULT) {
        this.result = state.executeToolResult;
        this.loading = false;
      }
    });
  }

  beforeDestroy() {
    this.unsubscribe();
  }
}
