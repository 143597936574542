








































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import store from "../store/index";
import { Actions, Mutations } from "../store/StoreActions";
import { ApiModels } from "../ApiModels/DataModel";
import HelpButton from './Shared/HelpButton.vue';
import PropertyEditor from './Shared/PropertyEditor.vue'

interface KeyValueType {
  entityId: string;
  name: string;
}

@Component({
    components: {
        'v-help-button': HelpButton,
        'v-property-editor': PropertyEditor
    }
})

export default class extends Vue {
  @Watch("name")
  onParametersChanged(value: string, oldValue: string) {
    if (value.toLowerCase().indexOf("command") > -1) {
      this.isCommand = true;
    }
    if (value.toLowerCase().indexOf(" ") > -1) {
      this.name = value.replace(" ", "");
    }
  }

  public props = {
    id: ""
  };

  private editMode = false;
  private id = "";
  private isCommand = false;
  private responseModels = new Array<KeyValueType>();
  private primitives = [
    "string",
    "integer",
    "boolean",
    "date",
    "uniqueidentifier",
    "long",
    "double",
    "decimal",
    "object"
  ];
  private request = new Array<ApiModels.ModelProperty>();
  private response = new ApiModels.PropertyType();
  private name = "";
  private rules = {
    required: (value: string) => !!value || "Required."
  };

  created() {
    this.loadForm();
  }

  loadForm() {
    if (this.$attrs.id != null) {
      this.id = this.$attrs.id;
      this.editMode = true;
      const existingQuery = this.$store.getters.getQueryByIdFromSelectedProject(
        this.$attrs.id
      );
      this.name = existingQuery.name;
      this.response = existingQuery.response;
      this.request = existingQuery.request;
      this.isCommand = existingQuery.isCommand;
    }

    const models = this.$store.getters.getModelsForSelectedProject() as Array<
      ApiModels.Model
    >;

    this.primitives.forEach(x => {
      this.responseModels.push({ entityId: x, name: x });
    });

    models.forEach(x => {
      this.responseModels.push({ entityId: x.entityId, name: x.name });
    });
  }

  saveQuery() {
    if ((this.$refs.form as any).validate()) {
      if (this.editMode) {
        const query = this.$store.getters.getQueryByIdFromSelectedProject(
          this.id
        );
        query.name = this.name;
        query.request = this.request;
        query.response = this.response;
        query.isCommand = this.isCommand;
        this.$store.commit(Mutations.UPDATE_QUERY, query);
      } else {
        const newQuery = new ApiModels.QueryDefinition();
        newQuery.name = this.name;
        newQuery.request = this.request;
        newQuery.response = this.response;
        newQuery.isCommand = this.isCommand;
        this.$store.commit(Mutations.CREATE_QUERY, newQuery);
      }

      this.$store.dispatch(Actions.SAVE_CURRENT_PROJECT);
      this.$router.push("/workspace");
    }
  }

  isModelPrimitive(value: string): boolean {
    return this.primitives.findIndex(x => x == value) > -1;
  }

  getPrimitiveValue(value: string) {
    if (this.isModelPrimitive(value)) {
      return value;
    } else {
      return "";
    }
  }

  getModelValue(value: string) {
    if (this.isModelPrimitive(value)) {
      return "";
    } else {
      return value;
    }
  }

  selectedResponseModelName() {
    const model = this.$store.getters.getModelByIdFromSelectedProject(
      this.response.type
    );
    if (model == null) {
      return this.response.type;
    } else {
      return model.name;
    }
  }

  selectResponseModel(entityId: string) {
    this.response.type = entityId;
  }

    propertyChangedEventHandler(properties: Array<ApiModels.ModelProperty>) {
    this.request =  properties.slice()
  }
}
