





















































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { Actions, Mutations } from "../store/StoreActions";
import { ApiModels } from "../ApiModels/DataModel";
@Component
export default class extends Vue {
  private myJobs = new Array<ApiModels.JobModel>();
  private enumJobPostingStatus = ApiModels.EnumJobPostingStatus;
  private unsubscribe: any;

  chipQuestions(job: ApiModels.JobModel) {
    return job.questions.filter(
      x => x.displayControlType == ApiModels.EnumDisplayControlType.Chip
    );
  }
  labelQuestions(job: ApiModels.JobModel) {
    return job.questions.filter(
      x => x.displayControlType == ApiModels.EnumDisplayControlType.Label
    );
  }
  mounted() {
    this.$store.dispatch(Actions.LOAD_MY_JOBS);
  }

  loadForm() {
    this.myJobs = this.$store.getters.getMyJobs;
  }

  created() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === Mutations.SET_MY_JOBS) {
        this.loadForm();
      }
    });
  }

  beforeDestroy() {
    this.unsubscribe();
  }
}
