







































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { Actions, Mutations, EventBusActions } from "../store/StoreActions";

import { ApiModels } from "../ApiModels/DataModel";
@Component
export default class extends Vue {
  private generationResults = new Array<ApiModels.GenerationResult>();
  private loading = false;
  private unsubscribe: any;

  mounted() {
    this.loadForm();
    this.generate();
  }

  loadForm() {
    const project = this.$store.getters.selectedProject;
    if (project.generationResult != null) {
      this.generationResults = project.generationResult;
    } else {
      this.generationResults = [];
    }
  }

  selectedProject() {
    return this.$store.getters.selectedProject;
  }

  getTemplateName(templateId: string) {
    const template = this.$store.getters.getTemplateById(templateId);
    if (template == null) {
      return "";
    } else {
      return template.displayName;
    }
  }

  getResultModelName(modelId: string) {
    const model = this.$store.getters.getModelByIdFromSelectedProject(modelId);
    if (model == null) {
      const routeCollection = this.$store.getters.getRouteCollectionByIdFromSelectedProject(
        modelId
      );
      if (routeCollection != null) {
        return routeCollection.name;
      } else {
        const query = this.$store.getters.getQueryByIdFromSelectedProject(
          modelId
        );
        if (query != null) {
          return query.name;
        } else {
          return "Setup";
        }
      }
    } else {
      return model.name;
    }
  }

  generate() {
    this.loading = true;
    this.$store.commit(Mutations.CLEAR_GENERATION_RESULT);
    this.$store.dispatch(
      Actions.GENERATE_PROJECT,
      this.$store.getters.selectedProject
    );
  }

  download() {
    this.$global.showSnackbar("Starting download...");
    this.$store.dispatch(
      Actions.DOWNLOAD_PROJECT,
      this.$store.getters.selectedProject
    );
  }

  copy(value: string) {
    this.$global.copyToClipoard(value);
  }

  created() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === Mutations.SET_GENERATION_RESULT) {
        this.loadForm();
        this.loading = false;
      }
    });
  }

  beforeDestroy() {
    this.unsubscribe();
  }
}
