export class Mutations {
  public static readonly CREATE_PROJECT: string = "CREATE_PROJECT";
  public static readonly UPDATE_PROJECT: string = "UPDATE_PROJECT";
  public static readonly DELETE_PROJECT: string = "DELETE_PROJECT";
  public static readonly CREATE_MODEL: string = "CREATE_MODEL";
  public static readonly UPDATE_MODEL: string = "UPDATE_MODEL";
  public static readonly DELETE_MODEL: string = "DELETE_MODEL";
  public static readonly SET_PROJECTS: string = "SET_PROJECTS";
  public static readonly SET_TEMPLATES: string = "SET_TEMPLATES";
  public static readonly ASSIGN_TEMPLATE_TO_MODEL: string =
    "ASSIGN_TEMPLATE_TO_MODEL";
  public static readonly UNASSIGN_TEMPLATE_FROM_MODEL: string =
    "UNASSIGN_TEMPLATE_FROM_MODEL";
  public static readonly ASSIGN_TEMPLATE_TO_ROUTE_COLLECTION: string =
    "ASSIGN_TEMPLATE_TO_ROUTE_COLLECTION";
  public static readonly UNASSIGN_TEMPLATE_FROM_ROUTE_COLLECTION: string =
    "UNASSIGN_TEMPLATE_FROM_ROUTE_COLLECTION";
  public static readonly ASSIGN_TEMPLATE_TO_QUERY: string =
    "ASSIGN_TEMPLATE_TO_QUERY";
  public static readonly UNASSIGN_TEMPLATE_FROM_QUERY: string =
    "UNASSIGN_TEMPLATE_FROM_QUERY";
  public static readonly ASSIGN_TEMPLATE_TO_SETUP: string =
    "ASSIGN_TEMPLATE_TO_SETUP";
  public static readonly UNASSIGN_TEMPLATE_FROM_SETUP: string =
    "UNASSIGN_TEMPLATE_FROM_SETUP";
  public static readonly SET_GENERATION_RESULT: string =
    "SET_GENERATION_RESULT";
  public static readonly SET_EXECUTE_TOOL_RESULT: string =
    "SET_EXECUTE_TOOL_RESULT";
  public static readonly CLEAR_GENERATION_RESULT: string =
    "CLEAR_GENERATION_RESULT";
  public static readonly SET_LOADING_PROJECTS: string = "SET_LOADING_PROJECTS";
  public static readonly SET_AUTH_TOKEN: string = "SET_AUTH_TOKEN";
  public static readonly SET_JOBS: string = "SET_JOBS";
  public static readonly SET_MY_JOBS: string = "SET_MY_JOBS";
  public static readonly SET_QUESTIONS: string = "SET_QUESTIONS";
  public static readonly CREATE_ROUTE: string = "CREATE_ROUTE";
  public static readonly DELETE_ROUTE: string = "DELETE_ROUTE";
  public static readonly UPDATE_ROUTE: string = "UPDATE_ROUTE";
  public static readonly CREATE_ROUTE_COLLECTION: string =
    "CREATE_ROUTE_COLLECTION";
  public static readonly UPDATE_ROUTE_COLLECTION: string =
    "UPDATE_ROUTE_COLLECTION";
  public static readonly DELETE_ROUTE_COLLECTION: string =
    "DELETE_ROUTE_COLLECTION";
  public static readonly CREATE_QUERY: string = "CREATE_QUERY";
  public static readonly DELETE_QUERY: string = "DELETE_QUERY";
  public static readonly UPDATE_QUERY: string = "UPDATE_QUERY";
  public static readonly UPDATE_MODEL_WITH_PARSE_PARAMETERS_RESULT: string =
    "UPDATE_MODEL_WITH_PARSE_PARAMETERS_RESULT";
  public static readonly SELECT_SETUP_MODEL: string = "SELECT_SETUP_MODEL";
  public static readonly DESELECT_SETUP_MODEL: string = "DESELECT_SETUP_MODEL";
  public static readonly PROJECT_SAVED: string = "PROJECT_SAVED";
  public static readonly SET_SELECTIONS: string = "PROJECT_SAVED";
  public static readonly SET_CURRENT_SWAGGER_URL: string =
    "SET_CURRENT_SWAGGER_URL";
  public static readonly SET_DEMO_PROJECTS: string = "SET_DEMO_PROJECTS";
  public static readonly CLEAR_TEST_MESSAGES: string = "CLEAR_TEST_MESSAGES";
  public static readonly ADD_TEST_MESSAGES: string = "ADD_TEST_MESSAGES";
}
export class Actions {
  public static readonly LOAD_PROJECTS: string = "LOAD_PROJECTS";
  public static readonly SAVE_PROJECTS: string = "SAVE_PROJECTS";
  public static readonly SAVE_PROJECT: string = "SAVE_PROJECT";
  public static readonly SAVE_CURRENT_PROJECT: string = "SAVE_CURRENT_PROJECT";
  public static readonly PARSE_MODEL: string = "PARSE_MODEL";
  public static readonly LOAD_TEMPLATES: string = "LOAD_TEMPLATES";
  public static readonly GENERATE_PROJECT: string = "GENERATE_PROJECT";
  public static readonly EXECUTE_TOOL: string = "EXECUTE_TOOL";
  public static readonly AUTHENTICATE: string = "AUTHENTICATE";
  public static readonly LOAD_JOBS: string = "LOAD_JOBS";
  public static readonly LOAD_MY_JOBS: string = "LOAD_MY_JOBS";
  public static readonly LOAD_QUESTIONS: string = "LOAD_QUESTIONS";
  public static readonly CREATE_JOB: string = "CREATE_JOB";
  public static readonly DOWNLOAD_PROJECT: string = "DOWNLOAD_PROJECT";
  public static readonly REQUEST_A_TOOL: string = "REQUEST_A_TOOL";
  public static readonly LOAD_DEMO: string = "LOAD_DEMO";
  public static readonly EXECUTE_JMES_TOOL: string = "EXECUTE_JMES_TOOL";
  public static readonly EASY_ASSIGN: string = "EASY_ASSIGN";
  public static readonly CREATE_FROM_SWAGGER_URL: string =
    "CREATE_FROM_SWAGGER_URL";
  public static readonly TEST_DEMO_PROJECTS: string = "TEST_DEMO_PROJECTS";
  public static readonly LOAD_ALL_DEMOS: string = "LOAD_ALL_DEMOS";
}

export class EventBusActions {
  public static readonly SHOW_SNACKBAR: string = "SHOW_SNACKBAR";
}
