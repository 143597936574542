
































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { ApiModels } from "../../ApiModels/DataModel";

interface KeyValuePair {
  id: string;
  name: string;
}

@Component
export default class extends Vue {

  @Prop()
  public properties!: Array<ApiModels.ModelProperty>;
  private componentProperties = new Array<ApiModels.ModelProperty>();
  private propertyTypes = new Array<KeyValuePair>();
  private primitives = [
    "string",
    "integer",
    "boolean",
    "date",
    "uniqueidentifier",
    "long",
    "double",
    "decimal",
    "object"
  ];


  mounted() {
      this.primitives.forEach(x => {
      this.propertyTypes.push({ id: x, name: x });
    });

    const models: Array<ApiModels.Model> = this.$store.getters.getModelsForSelectedProject();
    models.forEach(x => {
      this.propertyTypes.push({ id: x.entityId, name: x.name });
    });
  }

  created()
  {
      this.componentProperties = this.properties;
  }

  private deleteProperty(propertyName:string)
  {
    const index = this.componentProperties.findIndex(x => x.propertyName == name);
    this.componentProperties.splice(index, 1);
    this.propertyChangedEvent();
  }

    addProperty() 
    {
        const newProperty = new ApiModels.ModelProperty();
        newProperty.propertyType = new ApiModels.PropertyType();
        newProperty.propertyType.type = "string";
        newProperty.propertyType.isUserType = false;
        newProperty.propertyType.isCollection = false;
        newProperty.propertyType.isKey = false;
        this.componentProperties.splice(0, 0, newProperty);
        this.propertyChangedEvent();
    }

    getProperties()
    {
        return Object.assign({},this.componentProperties);
    }

  propertyChangedEvent() 
  {
    this.$emit('propertyChangedEvent', this.componentProperties);
  }
}
