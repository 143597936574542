





















import { Component, Prop, Vue } from "vue-property-decorator";
import store from "../store/index";
import { Actions, Mutations } from "../store/StoreActions";

@Component
export default class extends Vue {
  data() {
    return {
      toolTypes: [
        "JSON to C# Class",
        "Guid Generator",
        "Base64 Encoder",
        "Base64 Decoder",
        "C# Code Formatter",
        "JSON Formatter",
        "Decode JWT",
        "JMES Path"
      ],
      selectedTool: "",
      expand: false
    };
  }

  toolMenuSelect(toolName: string) {
    if (toolName == "Base64 Encoder") {
      this.$router.push({ path: "/tools/base64encoder" });
    } else if (toolName == "Base64 Decoder") {
      this.$router.push({ path: "/tools/base64decoder" });
    } else if (toolName == "Guid Generator") {
      this.$router.push({ path: "/tools/generateguid" });
    } else if (toolName == "C# Code Formatter") {
      this.$router.push({ path: "/tools/formatters/csharp" });
    } else if (toolName == "JSON Formatter") {
      this.$router.push({ path: "/tools/formatters/json" });
    } else if (toolName == "JMES Path") {
      this.$router.push({ path: "/tools/jmes" });
    } else if (toolName == "Decode JWT") {
      this.$router.push({ path: "/tools/jwt/decode" });
    } else if (toolName == "JSON to C# Class") {
      this.$router.push({ path: "/tools/parse/json2csharpclass" });
    }  else if (toolName == "RequestATool") {
      this.$router.push({ path: "/tools/requestatool" });
    } else {
     this.$global.logDebug("Unknown option:" + toolName);
    }
  }
}
