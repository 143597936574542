import { Dictionary } from "vue-router/types/router";

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace ApiModels {
  export enum EnumJobPostingStatus {
    None = 0,
    Submitted = 1,
    Approved = 2,
    Deleted = 3
  }

  export enum EnumInputControlType {
    None = 0,
    Switch = 1,
    Text = 2,
    Select = 3
  }

  export enum EnumDisplayControlType {
    None = 0,
    Chip = 1,
    Label = 2
  }

  export enum EnumHttpVerb {
    None = 0,
    Get = 1,
    Post = 2,
    Put = 3,
    Delete = 4,
    Patch = 5
  }

  export class AuthResponse {
    userId: number;
    emaild: string;
    avatarUrl: string;
    token: string;

    constructor() {
      this.userId = 0;
      this.emaild = "";
      this.avatarUrl = "";
      this.token = "";
    }
  }

  export class RequestAToolModel {
    description: string;
    email: string;
    constructor() {
      this.description = "";
      this.email = "";
    }
  }

  export class Question {
    question: string;
    shortSummary: string;
    answer: string;
    id: string;
    inputControlType: EnumInputControlType;
    displayControlType: EnumDisplayControlType;
    selectOptions: Array<string>;
    constructor() {
      this.answer = "";
      this.question = "";
      this.id = "";
      this.shortSummary = "";
      this.inputControlType = EnumInputControlType.None;
      this.displayControlType = EnumDisplayControlType.None;
      this.selectOptions = new Array<string>();
    }
  }
  export class JobModel {
    questions: Array<Question>;
    requiredTechnologies: Array<string>;
    niceToHaveTechnologies: Array<string>;
    id: number;
    externalId: string;
    headline: string;
    applyUrl: string;
    city: string;
    province: string;
    country: string;
    formattedDatePosted: string;
    jobPostingStatus: EnumJobPostingStatus;

    constructor() {
      this.questions = new Array<Question>();
      this.requiredTechnologies = new Array<string>();
      this.niceToHaveTechnologies = new Array<string>();
      this.id = 0;
      this.externalId = "";
      this.headline = "";
      this.applyUrl = "";
      this.city = "";
      this.province = "";
      this.country = "";
      this.formattedDatePosted = "";
      this.jobPostingStatus = EnumJobPostingStatus.None;
    }
  }
  export class JobsResponse {
    jobPostings: Array<JobModel>;

    constructor() {
      this.jobPostings = new Array<JobModel>();
    }
  }

  export class GenerationResult {
    id: string;
    success: boolean;
    result: any;
    templateId: string;
    modelId: string;
    errors: Array<GenerationError>;

    constructor() {
      this.success = false;
      this.result = "";
      this.id = "";
      this.templateId = "";
      this.modelId = "";
      this.errors = new Array<GenerationError>();
    }
  }

  export class GenerationError {
    message: string;

    constructor() {
      this.message = "";
    }
  }

  export class PropertyType {
    type: string;
    isUserType: boolean;
    isCollection: boolean;
    isKey: boolean;
    isNullable: boolean;
    constructor() {
      this.type = "";
      this.isNullable = false;
      this.isCollection = false;
      this.isUserType = false;
      this.isKey = false;
    }
  }

  export class GenerationModelDefinition {
    properties: Array<ModelProperty>;
    entityName: string;
    entityId: string;
    namespace: string;
    constructor() {
      this.properties = new Array<ModelProperty>();
      this.entityName = "";
      this.entityId = "";
      this.namespace = "";
    }
  }

  export class QueryDefinition {
    entityId: string;
    displayName: string;
    documentation: string;
    name: string;
    namespace: string;
    isCommand: boolean;
    request: Array<ModelProperty>;
    response: PropertyType;

    constructor() {
      this.entityId = "";
      this.displayName = "";
      this.documentation = "";
      this.name = "";
      this.namespace = "";
      this.isCommand = false;
      this.request = new Array<ModelProperty>();
      this.response = new PropertyType();
    }
  }

  export class QueryContainer {
    queries: Array<QueryDefinition>;
    constructor() {
      this.queries = new Array<QueryDefinition>();
    }
  }

  export class RouteCollection {
    name: string;
    id: string;
    entityId: string;
    baseRoute: string;
    routes: Array<GenerationRouteDefinition>;
    constructor() {
      this.name = "";
      this.id = "";
      this.entityId = "";
      this.baseRoute = "";
      this.routes = new Array<GenerationRouteDefinition>();
    }
  }

  export class GenerationRouteDefinition {
    route: string;
    httpVerb: EnumHttpVerb;
    id: string;
    entityId: string;
    queryId: string;
    response: PropertyType;
    constructor() {
      this.route = "";
      this.httpVerb = EnumHttpVerb.None;
      this.id = "";
      this.entityId = "";
      this.queryId = "";
      this.response = new PropertyType();
    }
  }

  export class ModelProperty {
    propertyName: string;
    propertyType: PropertyType;
    constructor() {
      this.propertyName = "";
      this.propertyType = new PropertyType();
    }
  }

  export class ModelContainer {
    models: Array<GenerationModelDefinition>;
    constructor() {
      this.models = new Array<GenerationModelDefinition>();
    }
  }

  export class ParseResult {
    success: boolean;
    errors: Array<string>;
    modelContainer: ModelContainer;
    queryContainer: QueryContainer;
    routeCollections: Array<RouteCollection>;
    constructor() {
      this.success = false;
      this.errors = [];
      this.modelContainer = new ModelContainer();
      this.queryContainer = new QueryContainer();
      this.routeCollections = new Array<RouteCollection>();
    }
  }

  export class Model {
    entityId: string;
    modelTypeId: string;
    name: string;
    parameters: string;
    parsedModel: GenerationModelDefinition;
    modelType: string;

    constructor() {
      this.entityId = "";
      this.modelTypeId = "";
      this.name = "";
      this.parameters = "";
      this.modelType = "";
      this.parsedModel = new GenerationModelDefinition();
    }
  }

  export class SetupModel {
    id: string;
    modelTypeId: string;
    name: string;
    parameters: string;

    constructor() {
      this.id = "832e9b24-6cdb-4fa4-beec-a951d669d9fd";
      this.modelTypeId = "88ebd8fd-f87a-481f-b5ca-a6d0db22dd53";
      this.name = "Setup";
      this.parameters = "";
    }
  }

  export class ProjectTemplate {
    templateId: string;
    templateVersion: number;
    modelId: string;
    name: string;

    constructor() {
      this.templateId = "";
      this.templateVersion = 1;
      this.modelId = "";
      this.name = "";
    }
  }

  export class Template {
    templateId: string;
    collectionId: string;
    templateVersion: number;
    modelTypeId: string;
    displayName: string;
    description: string;
    technology: Array<string>;
    constructor() {
      this.templateId = "";
      this.modelTypeId = "";
      this.displayName = "";
      this.description = "";
      this.collectionId = "";
      this.templateVersion = 0;
      this.technology = [];
    }
  }

  export class Project {
    id: string;
    version: number;
    projectTemplates: Array<ProjectTemplate>;
    models: Array<Model>;
    routeCollections: Array<RouteCollection>;
    queryContainer: QueryContainer;
    name: string;
    markedForDeletion: boolean;
    environmentVariables: string;
    generationResult: Array<GenerationResult>;
    setupModel: SetupModel;
    isDemo: boolean;
    constructor() {
      this.id = "";
      this.version = 0;
      this.projectTemplates = new Array<ProjectTemplate>();
      this.models = new Array<Model>();
      this.routeCollections = new Array<RouteCollection>();
      this.generationResult = new Array<GenerationResult>();
      this.queryContainer = new QueryContainer();
      this.name = "";
      this.markedForDeletion = false;
      this.environmentVariables = "";
      this.setupModel = new SetupModel();
      this.isDemo = false;
    }
  }

  export class ModelType {
    id: string;
    documentation: string;
    displayName: string;
    sampleInput: string;

    constructor() {
      this.id = "";
      this.documentation = "";
      this.displayName = "";
      this.sampleInput = "";
    }
  }

  export class Profile {
    projects: Array<Project>;
    constructor() {
      this.projects = new Array<Project>();
    }
  }

  export interface DemoResponseType {
    name: string;
    url: string;
    externalId: string;
  }

  export interface TestMessageType {
    success: boolean;
    step: string;
    info: string;
  }

  export class DataModel {
    projects: Array<Project>;
    selectedProjectId: string;
    selectedModelId: string;
    selectedRouteCollectionId: string;
    selectedQueryId: string;
    setupSelected: boolean;
    modelTypes: Array<ModelType>;
    templates: Array<Template>;
    executeToolResult: string;
    parseResult: ParseResult;
    loadingProjects: boolean;
    authResponse: ApiModels.AuthResponse;
    jobs: ApiModels.JobsResponse;
    myJobs: ApiModels.JobsResponse;
    questions: Array<ApiModels.Question>;
    currentSwaggerUrl: string;
    demoProjects: Array<DemoResponseType>;
    testMessages: Array<TestMessageType>;

    constructor() {
      this.projects = new Array<Project>();
      this.modelTypes = new Array<ModelType>();
      this.templates = new Array<Template>();
      this.selectedProjectId = "";
      this.currentSwaggerUrl = "";
      this.selectedRouteCollectionId = "";
      this.selectedQueryId = "";
      this.selectedModelId = "";
      this.executeToolResult = "";
      this.parseResult = new ParseResult();
      this.loadingProjects = false;
      this.authResponse = new ApiModels.AuthResponse();
      this.jobs = new ApiModels.JobsResponse();
      this.myJobs = new ApiModels.JobsResponse();
      this.questions = new Array<ApiModels.Question>();
      this.setupSelected = false;
      this.demoProjects = new Array<DemoResponseType>();
      this.testMessages = new Array<TestMessageType>();
    }
  }
}
