











































































































































































































































































































































































































































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import store from "../store/index";
import { Actions, Mutations, EventBusActions } from "../store/StoreActions";
import { ApiModels } from "../ApiModels/DataModel";
import HelpButton from './Shared/HelpButton.vue'
@Component({
    components: {
        'v-help-button': HelpButton
    }
})

export default class extends Vue {
  private models = new Array<ApiModels.Model>();
  private routeCollections = new Array<ApiModels.RouteCollection>();
  private queries = new Array<ApiModels.QueryDefinition>();
  private assignedTemplates = new Array<ApiModels.Template>();
  private unassignedTemplates = new Array<ApiModels.Template>();
  private panelIndex = null;
  private modelFilter = "";
  private assignedFilter = "";
  private unassignedFilter = "";
  private componentMounted = false;
  private unsubscribe: any;
  

  @Watch("modelFilter")
  onModelFilterChanged(value: string, oldValue: string) {
    this.modelFilter = value;
    this.loadModels();
    this.loadRouteCollections();
    this.loadQueries();
  }

  @Watch("assignedFilter")
  onAssignedFilterChanged(value: string, oldValue: string) {
    this.assignedFilter = value;
    this.loadAssignedTemplates();
  }

  @Watch("unassignedFilter")
  onUnassignedFilterChanged(value: string, oldValue: string) {
    this.unassignedFilter = value;
    this.loadUnassignedTemplates();
  }

  mounted() {
    this.componentMounted = true;
    this.loadForm();
  }

  loadForm() {
    if (this.componentMounted) {
      this.loadModels();
      this.loadRouteCollections();
      this.loadQueries();
      this.loadAssignedTemplates();
      this.loadUnassignedTemplates();
    }
  }

  loadRouteCollections() {
    if (this.modelFilter != "") {
      this.routeCollections = (this.$store.getters
        .getSelectedProjectRouteCollections as Array<
        ApiModels.RouteCollection
      >).filter(x =>
        x.name.toUpperCase().includes(this.modelFilter.toUpperCase())
      );
    } else {
      this.routeCollections = this.$store.getters.getSelectedProjectRouteCollections;
    }

    this.routeCollections = this.routeCollections.sort((a, b) =>
      a.name.localeCompare(b.name)
    );
  }

  loadModels() {
    if (this.modelFilter != "") {
      this.models = (this.$store.getters.selectedProjectModels as Array<
        ApiModels.Model
      >).filter(x =>
        x.name.toUpperCase().includes(this.modelFilter.toUpperCase())
      );
    } else {
      this.models = this.$store.getters.selectedProjectModels;
    }

    this.models = this.models.sort((a, b) => a.name.localeCompare(b.name));
  }

  loadQueries() {
    if (this.modelFilter != "") {
      this.queries = (this.$store.getters.getQueriesForSelectedProject as Array<
        ApiModels.QueryDefinition
      >).filter(x =>
        x.name.toUpperCase().includes(this.modelFilter.toUpperCase())
      );
    } else {
      this.queries = this.$store.getters.getQueriesForSelectedProject;
    }

    this.queries = this.queries.sort((a, b) => a.name.localeCompare(b.name));
  }

  assignedTemplateCountForModel(modelId: string) {
    return this.$store.getters.getAssignedTemplateCountForModel(modelId);
  }

  selectedRouteCollectionId() {
    return this.$store.state.selectedRouteCollectionId;
  }

  selectedQueryId() {
    return this.$store.state.selectedQueryId;
  }

  isSetupSelected() {
    return this.$store.getters.getSetupSelected;
  }

  loadAssignedTemplates() {
    if (this.assignedFilter != "") {
      this.assignedTemplates = (this.$store.getters.assignedTemplates as Array<
        ApiModels.Template
      >).filter(x =>
        x.displayName.toUpperCase().includes(this.assignedFilter.toUpperCase())
      );
    } else {
      this.assignedTemplates = this.$store.getters.assignedTemplates;
    }
  }

  loadUnassignedTemplates() {
    if (this.unassignedFilter != "") {
      this.unassignedTemplates = (this.$store.getters
        .unassignedTemplatesas as Array<ApiModels.Template>).filter(x =>
        x.displayName
          .toUpperCase()
          .includes(this.unassignedFilter.toUpperCase())
      );
    } else {
      this.unassignedTemplates = this.$store.getters.unassignedTemplates;
    }
  }

  selectedProject() {
    return this.$store.getters.selectedProject;
  }

  selectedProjectSetupId() {
    const project = this.$store.getters.selectedProject;
    return project.setupModel.id;
  }

  selectedModelId() {
    const selectedModel = this.$store.getters.selectedModel;
    if (selectedModel == null) {
      return "";
    } else {
      return selectedModel.entityId;
    }
  }

  deleteRouteCollection(id: string) {
    this.$store.commit(Mutations.DELETE_ROUTE_COLLECTION, id);
    this.$store.dispatch(Actions.SAVE_CURRENT_PROJECT);
  }

  deleteRoute(routeId: string) {
    this.$store.commit(Mutations.DELETE_ROUTE, routeId);
    this.$store.dispatch(Actions.SAVE_CURRENT_PROJECT);
  }

  editRoute(id: string) {
    this.$router.push("/routes/edit/" + id);
  }

  editRouteCollection(id: string) {
    this.$router.push("/routecollections/edit/" + id);
  }

  selectSetup() {
    this.$store.commit(Mutations.SET_SELECTIONS, {
      selectedQueryId: "",
      selectedModelId: "",
      selectedRouteCollectionId: "",
      setupSelected: true
    });
  }

  selectModel(id: string) {
    this.$store.commit(Mutations.SET_SELECTIONS, {
      selectedQueryId: "",
      selectedModelId: id,
      selectedRouteCollectionId: "",
      setupSelected: false
    });
  }

  selectRouteCollection(id: string) {
    this.$store.commit(Mutations.SET_SELECTIONS, {
      selectedQueryId: "",
      selectedModelId: "",
      selectedRouteCollectionId: id,
      setupSelected: false
    });
  }

  selectQuery(id: string) {
    this.$store.commit(Mutations.SET_SELECTIONS, {
      selectedQueryId: id,
      selectedModelId: "",
      selectedRouteCollectionId: "",
      setupSelected: false
    });
  }

  getModelNameFromId(id: string) {
    if (id == null) {
      return "";
    } else {
      const model = this.$store.getters.getModelByIdFromSelectedProject(id);
      return model.name;
    }
  }

  assignTemplate(templateId: string) {
    if (this.$store.state.selectedRouteCollectionId != "") {
      this.$store.commit(
        Mutations.ASSIGN_TEMPLATE_TO_ROUTE_COLLECTION,
        templateId
      );
    } else if (this.$store.state.selectedQueryId != "") {
      this.$store.commit(Mutations.ASSIGN_TEMPLATE_TO_QUERY, templateId);
    } else if (this.$store.state.selectedModelId != "") {
      this.$store.commit(Mutations.ASSIGN_TEMPLATE_TO_MODEL, templateId);
    } else {
      this.$store.commit(Mutations.ASSIGN_TEMPLATE_TO_SETUP, templateId);
    }

    this.$store.dispatch(Actions.SAVE_CURRENT_PROJECT);
  }

  unassignTemplate(templateId: string) {
    if (this.$store.state.selectedRouteCollectionId != "") {
      this.$store.commit(
        Mutations.UNASSIGN_TEMPLATE_FROM_ROUTE_COLLECTION,
        templateId
      );
    } else if (this.$store.state.selectedQueryId != "") {
      this.$store.commit(Mutations.UNASSIGN_TEMPLATE_FROM_QUERY, templateId);
    } else if (this.$store.state.selectedModelId != "") {
      this.$store.commit(Mutations.UNASSIGN_TEMPLATE_FROM_MODEL, templateId);
    } else {
      this.$store.commit(Mutations.UNASSIGN_TEMPLATE_FROM_SETUP, templateId);
    }

    this.$store.dispatch(Actions.SAVE_CURRENT_PROJECT);
  }

  deleteModel(id: string) {
    this.$store.commit(Mutations.DELETE_MODEL, id);
    this.$store.dispatch(Actions.SAVE_CURRENT_PROJECT);
  }

  editQuery(id: string) {
    this.$router.push("/queries/edit/" + id);
  }

  deleteQuery(id: string) {
    this.$store.commit(Mutations.DELETE_QUERY, id);
    this.$store.dispatch(Actions.SAVE_CURRENT_PROJECT);
  }

  editModel(id: string) {
    this.$router.push("/models/edit/" + id);
  }

  formatHttpVerb(httpVerb: ApiModels.EnumHttpVerb) {
    return ApiModels.EnumHttpVerb[httpVerb];
  }

  getRouteBaseRoute(routeId: string) {
    return this.$store.getters.getRouteBaseRoute(routeId);
  }
  
  download() {
    this.$store.dispatch(
      Actions.DOWNLOAD_PROJECT,
      this.$store.getters.selectedProject
    );
    this.$global.showSnackbar("Starting download...");
  }

  generate() {
    this.$store.commit(Mutations.CLEAR_GENERATION_RESULT);
    this.$store.dispatch(
      Actions.GENERATE_PROJECT,
      this.$store.getters.selectedProject
    );
    this.$router.push("/generate");
  }

  created() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (
        mutation.type === Mutations.PROJECT_SAVED ||
        mutation.type === Mutations.SET_SELECTIONS
      ) {
        this.loadForm();
      }
    });
  }

  beforeDestroy() {
    this.unsubscribe();
  }

  trimLongEntityName(entityName: string) {
    if (entityName.length > 24) {
      return entityName.slice(0, 21) + "...";
    } else {
      return entityName;
    }
  }

  getModelPropertyType(property: ApiModels.ModelProperty) {
    let response = "";
    const primitives = [
      "string",
      "integer",
      "boolean",
      "date",
      "uniqueidentifier",
      "long",
      "double",
      "decimal",
      "object"
    ];
    if (primitives.indexOf(property.propertyType.type) < 0) {
      const model = this.$store.getters.getModelByIdFromSelectedProject(
        property.propertyType.type
      );
      if (model != null) {
        response = model.name;
        if (property.propertyType.isNullable) {
          response += "?";
        }
        if (property.propertyType.isCollection) {
          response = "Collection<" + response + ">";
        }
      }
    } else {
      response = property.propertyType.type;
    }

    return response;
  }
  getQueryDisplay(definition: ApiModels.PropertyType) {
    let response = "";

    if (definition == null || definition.type == "") {
      response = "none";
    } else {
      const model = this.$store.getters.getModelByIdFromSelectedProject(
        definition.type
      );
      if (model == null) {
        response = definition.type;
      } else {
        response = model.name;
      }

      if (definition.isNullable) {
        response += "?";
      }
      if (definition.isCollection) {
        response = "Collection<" + response + ">";
      }
    }
    return response;
  }

  getRequestQueryDisplay(definition: Array<ApiModels.ModelProperty>) {
    const response = new Array<string>();

    definition.forEach(x => {
      response.push(
        x.propertyName + ":" + this.getQueryDisplay(x.propertyType)
      );
    }, this);

    return response;
  }

  easyAssign() {
    this.$store.dispatch(Actions.EASY_ASSIGN);
    this.selectSetup();
    this.$global.showSnackbar("Templates assigned...");
  }
}
