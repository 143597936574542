

































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { Actions, Mutations } from "../store/StoreActions";
import { ApiModels } from "../ApiModels/DataModel";
@Component
export default class extends Vue {
  private headline = "";
  private applicationUrl = "";
  private city = "";
  private province = "";
  private country = "";
  private niceToHaveTechnologies = new Array<string>();
  private requiredTechnologies = new Array<string>();
  private technologyTypes = [
    ".NET",
    "Go",
    "PHP",
    "React",
    "Angular",
    "Vue",
    "Ember",
    "SQL Server",
    "MySQL",
    "PostgreSQL",
    "AWS",
    "Azure",
    "DevOps",
    "NodeJS"
  ];
  private questions = new Array<ApiModels.Question>();
  private rules = {
    required: (value: string) => !!value || "Required."
  };
  private loggedIn = false;
  private unsubscribe: any;

  mounted() {
    this.$store.dispatch(Actions.LOAD_QUESTIONS);
    this.loggedIn = this.$store.getters.getIsUserAuthenticated();
  }

  isQuestionSwitch(question: ApiModels.Question) {
    return question.inputControlType == ApiModels.EnumInputControlType.Switch;
  }
  isQuestionLabel(question: ApiModels.Question) {
    return question.inputControlType == ApiModels.EnumInputControlType.Text;
  }
  isQuestionSelect(question: ApiModels.Question) {
    return question.inputControlType == ApiModels.EnumInputControlType.Select;
  }

  submit() {
    if ((this.$refs.form as any).validate()) {
      const model = new ApiModels.JobModel();
      model.headline = this.headline;
      model.applyUrl = this.applicationUrl;
      model.city = this.city;
      model.province = this.province;
      model.country = this.country;
      model.requiredTechnologies = this.requiredTechnologies;
      model.niceToHaveTechnologies = this.niceToHaveTechnologies;
      model.questions = this.questions;

      this.$store.dispatch(Actions.CREATE_JOB, model);
      this.$router.push("/jobs/myjobs");
    }
  }

  loadForm() {
    this.questions = this.$store.getters.getQuestions;
  }

  created() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === Mutations.SET_QUESTIONS) {
        this.loadForm();
      }
    });
  }

  beforeDestroy() {
    this.unsubscribe();
  }
}
