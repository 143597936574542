
































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import store from "../store/index";
import { Actions, Mutations, EventBusActions } from "../store/StoreActions";
import { ApiModels } from "../ApiModels/DataModel";
import HelpButton from './Shared/HelpButton.vue'
@Component({
    components: {
        'v-help-button': HelpButton
    }
})

export default class extends Vue {
  public props = {
    id: ""
  };

  @Watch("route")
  onParametersChanged(value: string, oldValue: string) {
    if (value.toLowerCase() == "id" || value.toLowerCase() == "{id") {
      this.route = "{id:integer}";
    }
  }

  private editMode = false;
  private id = "";
  private routeCollections = new Array<ApiModels.RouteCollection>();
  private routeCollectionId = "";
  private queries = new Array<ApiModels.QueryDefinition>();
  private selectedQueryId = "";
  private httpVerbs = ["Get", "Post", "Put", "Delete", "Patch"];
  private httpVerb = "Get";
  private route = "";
  private rules = {
    required: (value: string) => !!value || "Required."
  };

  mounted() {
    this.loadForm();
  }

  loadForm() {
    this.routeCollections = this.$store.getters.getRouteCollectionForSelectedProject();
    this.queries = this.$store.getters.getQueriesForSelectedProject;
    if (this.$attrs.id != null) {
      this.id = this.$attrs.id;
      this.editMode = true;
      const existingRoute = this.$store.getters.getRouteByIdFromSelectedProject(
        this.$attrs.id
      );
      this.route = existingRoute.route;
      this.httpVerb = ApiModels.EnumHttpVerb[existingRoute.httpVerb];
      this.selectedQueryId = existingRoute.queryId;
      this.routeCollectionId = this.$store.getters.getRouteCollectionIdForRouteForSelectedProject(
        this.id
      );
    } else {
      this.routeCollectionId = this.$store.getters.getLatestAddedRouteCollectionForSelectedProject;
    }
  }

  saveRoute() {
    if (this.selectedQueryId == "") {
      this.$global.showSnackbar("Query/Command is required!");
      return;
    }
    if ((this.$refs.form as any).validate()) {
      const convertedRoute = ApiModels.EnumHttpVerb[this.httpVerb as any];
      if (this.editMode) {
        const route = this.$store.getters.getRouteByIdFromSelectedProject(
          this.id
        );
        route.httpVerb = convertedRoute;
        route.route = this.route;
        route.queryId = this.selectedQueryId;
        const parameters = {
          route: route,
          routeCollectionId: this.routeCollectionId
        };
        this.$store.commit(Mutations.UPDATE_ROUTE, parameters);
      } else {
        const newRoute = new ApiModels.GenerationRouteDefinition();
        newRoute.httpVerb = convertedRoute as any;
        newRoute.route = this.route;
        newRoute.queryId = this.selectedQueryId;
        const parameters = {
          route: newRoute,
          routeCollectionId: this.routeCollectionId
        };
        this.$store.commit(Mutations.CREATE_ROUTE, parameters);
      }

      this.$store.dispatch(Actions.SAVE_CURRENT_PROJECT);
      this.$router.push("/workspace");
    }
  }

  selectQuery(queryId: string) {
    this.selectedQueryId = queryId;
  }

  selectedQueryName() {
    const selectedQuery = this.$store.getters.getQueryByIdFromSelectedProject(
      this.selectedQueryId
    );
    if (selectedQuery == null) {
      return "";
    } else {
      return selectedQuery.name;
    }
  }
}
