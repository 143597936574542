































import { Component, Prop, Vue } from "vue-property-decorator";
import store from "../store/index";
import { Actions, Mutations, EventBusActions } from "../store/StoreActions";
import { ApiModels } from "../ApiModels/DataModel";
@Component
export default class extends Vue {
  private description = "";
  private email = "";
  private rules = {
    required: (value: string) => !!value || "Required."
  };

  submit() {
    if ((this.$refs.form as any).validate()) {
      const request = new ApiModels.RequestAToolModel();
      request.description = this.description;
      request.email = this.email;
      this.$store.dispatch(Actions.REQUEST_A_TOOL, request);
      this.$global.showSnackbar("Request submitted!");
    }
  }
}
