






import { Component, Prop, Vue } from "vue-property-decorator";
import store from "../store/index";
import { Actions, Mutations } from "../store/StoreActions";

@Component
export default class extends Vue {
  public props = {
    code: "",
    token: ""
  };

  private code = "";
  private token = "";
  private unsubscribe: any;

  mounted() {
    if (this.$attrs.code != null) {
      this.code = this.$attrs.code;
      this.$store.dispatch(Actions.AUTHENTICATE, this.code);
    }
  }

  created() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === Mutations.SET_AUTH_TOKEN) {
        this.$router.push("/");
      }
    });
  }

  beforeDestroy() {
    this.unsubscribe();
  }
}
