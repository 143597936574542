















































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import store from "../store/index";
import { Actions, Mutations, EventBusActions } from "../store/StoreActions";
import { ApiModels } from "../ApiModels/DataModel";
import HelpButton from './Shared/HelpButton.vue'
import PropertyEditor from './Shared/PropertyEditor.vue'

@Component({
    components: {
        'v-help-button': HelpButton,
        'v-property-editor': PropertyEditor
    }
})

export default class extends Vue {
  public props = {
    id: ""
  };

 
  @Watch("parameters")
  onParametersChanged(value: string, oldValue: string) {
    if (value.toLowerCase().indexOf("swagger.json") > -1) {
      this.modelType = "Swagger Definition";
    } else if (value.toLowerCase().indexOf('swagger:') > -1) {
      this.modelType = "Swagger Definition";
    } else if (value.toLowerCase().indexOf("public class") > -1) {
      this.modelType = "C# Class";
    } else if (value.toLowerCase().indexOf("export class") > -1) {
      this.modelType = "Typescript Class";
    } else {
      try {
        if (JSON.parse(value)) {
          this.modelType = "JSON";
        }
      } catch (e) {
        // not valid json
      }
    }
  }

  private entityId = "";
  private models = new Array<ApiModels.Model>();
  private selectedModelName = "";
  private editMode = false;
  private parameters = "";
  private modelType = "";
  private properties = new Array<ApiModels.ModelProperty>();

  private name = "";
  private parseModelTypes = [
    "Simple Model Syntax",
    "JSON",
    "Typescript Class",
    "C# Class",
    "Swagger Definition"
  ];
  private rules = {
    required: (value: string) => !!value || "Required."
  };

  private unsubscribe: any;

  mounted() {
    this.loadForm();
  }

  loadForm() {
    this.models = this.$store.getters.getModelsForSelectedProject();
    const selectedModel = this.$store.getters.selectedModel;
    if (selectedModel != null) {
      this.selectedModelName = selectedModel.name;
    }

    this.modelType = "Simple Model Syntax";
    if (this.$attrs.id != null) {
      this.entityId = this.$attrs.id;
      this.editMode = true;
      this.$store.commit(Mutations.SET_SELECTIONS, {
        selectedModelId: this.entityId
      });
      const savedModel = this.$store.getters.getModelByIdFromSelectedProject(
        this.entityId
      );
      this.name = savedModel.name;
      this.parameters = savedModel.parameters;
      this.modelType = savedModel.modelType;
      this.setCurrentModelParseResultProperties();
    }

   

    (this.$refs["name"] as any).focus();

    this.getModelType();
  }

  getModelType() {
    const savedModel = this.$store.getters.getModelByNameFromSelectedProject(
      this.name
    );
    if (savedModel == null) {
      this.modelType = "Simple Model Syntax";
    } else {
      this.modelType = savedModel.parsedModel.modelType;
      this.parameters = savedModel.parameters;
    }
  }

  getParseResult() {
    const parseResult = this.$store.getters.getParseResult;
    if (parseResult == null) {
      return new ApiModels.ParseResult();
    } else {
      return parseResult;
    }
  }

  setCurrentModelParseResultProperties() {
    const savedModel = this.$store.getters.getModelByNameFromSelectedProject(
      this.name
    );
    if (savedModel == null) {
      this.properties = Array<ApiModels.ModelProperty>();
    } else {
      if (savedModel.parsedModel == null) {
        this.properties = Array<ApiModels.ModelProperty>();
      } else {
        this.properties = Object.assign([], savedModel.parsedModel.properties);
      }
    }
  }

  parseModel() {
    if (this.parameters != "") {
      const project = this.$store.getters.selectedProject;
      this.$store.dispatch(Actions.PARSE_MODEL, {
        name: this.name,
        projectId: project.id,
        model: { parameters: this.parameters, modelType: this.modelType }
      });
    } else {
      this.$global.showSnackbar("Parameters are required!");
    }
  }

  saveModel() {
      if (this.editMode) {
        const existingModel = this.$store.getters.getModelByIdFromSelectedProject(
          this.entityId
        );
        existingModel.parameters = this.parameters;
        existingModel.modelType = this.modelType;
        existingModel.name = this.name;
        existingModel.parsedModel.properties = this.properties;
        this.$store.commit(Mutations.UPDATE_MODEL, existingModel);
        this.$store.dispatch(Actions.SAVE_CURRENT_PROJECT);
        this.loadForm();
        this.$global.showSnackbar("Model saved!");
      } else {
        const model: ApiModels.Model = new ApiModels.Model();
        model.name = this.name;
        model.parameters = this.parameters;
        model.modelType = this.modelType;
        model.parsedModel.properties = this.properties;
        this.$store.commit(Mutations.CREATE_MODEL, model);
        this.$store.dispatch(Actions.SAVE_CURRENT_PROJECT);
        const selectedModel = this.$store.getters.selectedModel;
        this.$router.push("/models/edit/" + selectedModel.entityId);
      }
  }

  selectModel(entityId: string) {
    this.$store.commit(Mutations.SET_SELECTIONS, {
      selectedQueryId: "",
      selectedModelId: entityId,
      selectedRouteCollectionId: "",
      setupSelected: false
    });
    this.$router.push("/models/edit/" + entityId);
  }

  copy(value: object) {
    this.$global.copyToClipoard(JSON.stringify(value, null, 1));
  }

  propertyChangedEventHandler(properties: Array<ApiModels.ModelProperty>) {
    this.properties =  properties.slice()
  }

  created() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (
        mutation.type === Mutations.UPDATE_MODEL_WITH_PARSE_PARAMETERS_RESULT
      ) {
        this.$global.showSnackbar("Model has been parsed!");
        this.$router.push("/workspace");
      }
      if (mutation.type === Mutations.SET_PROJECTS) {
        this.loadForm();
      }
    });
  }

  beforeDestroy() {
    this.unsubscribe();
  }
}
