var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('div',[_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",attrs:{"small":"","dark":"","color":"primary","to":"/models/create"}},on),[_vm._v(" Models ")])]}}])},[_c('span',[_vm._v("Add a model")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",attrs:{"small":"","dark":"","color":"primary","to":"/queries/create"}},on),[_vm._v(" Queries ")])]}}])},[_c('span',[_vm._v("Add a query or command")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",attrs:{"small":"","dark":"","color":"primary","to":"/routes/create"}},on),[_vm._v(" Routes ")])]}}])},[_c('span',[_vm._v("Add a route")])])],1),_c('div',{staticStyle:{"margin-top":"15px","display":"flex"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",attrs:{"fab":"","x-small":"","dark":"","color":"red"},nativeOn:{"click":function($event){return _vm.easyAssign()}}},on),[_vm._v(" Easy ")])]}}])},[_c('span',[_vm._v("Assign all appropriate templates to everything in your project and make it ready to generate.")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",attrs:{"x-small":"","fab":"","dark":"","color":"primary"},nativeOn:{"click":function($event){return _vm.download()}}},on),[_c('v-icon',[_vm._v("mdi-cloud-download")])],1)]}}])},[_c('span',[_vm._v("Generate and download project in a zip file.")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",attrs:{"x-small":"","fab":"","dark":"","color":"primary"},nativeOn:{"click":function($event){return _vm.generate()}}},on),[_c('v-icon',[_vm._v("mdi-flash")])],1)]}}])},[_c('span',[_vm._v("Generate project and view output in web viewer")])]),_c('v-help-button',{attrs:{"square":false,"noFloat":true,"url":"https://www.codenesium.com/documentation/gettingstarted/Workspace"}})],1)]),_c('v-row',[_c('div',{key:1,class:_vm.panelIndex == null
          ? 'col'
          : '-webkit-box-flex: 1;-ms-flex-positive: 1;flex-grow: 1;'},[_c('v-text-field',{attrs:{"label":"Filter","placeholder":"Filter"},model:{value:(_vm.modelFilter),callback:function ($$v) {_vm.modelFilter=$$v},expression:"modelFilter"}}),_c('v-expansion-panels',{model:{value:(_vm.panelIndex),callback:function ($$v) {_vm.panelIndex=$$v},expression:"panelIndex"}},[_c('v-expansion-panel',{on:{"click":function($event){return _vm.selectSetup()}}},[_c('v-expansion-panel-header',{staticClass:"body-1 mb-1",style:(_vm.isSetupSelected() ? 'color:green' : 'color:black')},[_vm._v("Setup ("+_vm._s(_vm.assignedTemplateCountForModel(_vm.selectedProjectSetupId()))+") ")]),_c('v-expansion-panel-content',[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[_vm._v("Setup is a special item that cannot be removed. Project level templates and configuration can be assigned to this item. ")])],1)],1)],1),_vm._l((this.routeCollections),function(routeCollection){return _c('v-expansion-panel',{key:routeCollection.id,on:{"click":function($event){return _vm.selectRouteCollection(routeCollection.id)}}},[_c('v-expansion-panel-header',{staticClass:"body-1 mb-1",style:(_vm.selectedRouteCollectionId() == routeCollection.id
                ? 'color:green'
                : 'color:black')},[_vm._v(_vm._s(_vm.trimLongEntityName(routeCollection.name))+" ("+_vm._s(_vm.assignedTemplateCountForModel(routeCollection.id))+") "),_c('div',{staticStyle:{"display":"inline","max-width":"60px"}},[_c('v-chip',{staticClass:"mx-2",attrs:{"color":"green","small":"","text-color":"white"}},[_vm._v("R ")])],1)]),_c('v-expansion-panel-content',[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[_vm._v(" "+_vm._s(routeCollection.name)+" "),_vm._l((routeCollection.routes),function(route){return _c('div',{key:route.id},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":""},on:{"click":function($event){return _vm.editRoute(route.id)}}},on),[_vm._v(_vm._s(_vm.getRouteBaseRoute(route.id))+_vm._s(route.route))])]}}],null,true)},[_c('span',[_vm._v("Edit this route")])]),_c('div',{staticStyle:{"display":"inline"}},[_c('v-chip',{staticClass:"mx-2",attrs:{"color":"green","small":"","text-color":"white"}},[_vm._v(" "+_vm._s(_vm.formatHttpVerb(route.httpVerb))+" ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"red"},nativeOn:{"click":function($event){return _vm.deleteRoute(route.id)}}},on),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete this route")])])],1)],1)})],2)],1),_c('v-card-actions',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"blue darken-3"},nativeOn:{"click":function($event){return _vm.editRouteCollection(routeCollection.id)}}},on),[_c('v-icon',[_vm._v("mdi-file-edit")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit this route collection")])]),_c('div',{staticStyle:{"position":"absolute","bottom":"15px","right":"15px"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"red"},nativeOn:{"click":function($event){return _vm.deleteRouteCollection(routeCollection.id)}}},on),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete this route collection")])])],1)],1)],1)],1)}),_vm._l((this.models),function(model){return _c('v-expansion-panel',{key:model.name,on:{"click":function($event){return _vm.selectModel(model.entityId)}}},[_c('v-expansion-panel-header',{staticClass:"body-1 mb-1",style:(_vm.selectedModelId() == model.entityId
                ? 'color:green'
                : 'color:black')},[_vm._v(_vm._s(_vm.trimLongEntityName(model.name))+" ("+_vm._s(_vm.assignedTemplateCountForModel(model.entityId))+") "),_c('div',{staticStyle:{"display":"inline","max-width":"60px"}},[_c('v-chip',{staticClass:"mx-2",attrs:{"color":"blue","small":"","text-color":"white"}},[_vm._v("M ")])],1)]),_c('v-expansion-panel-content',[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[_vm._v(" "+_vm._s(model.name)+" "),_vm._l((model.parsedModel.properties),function(property){return _c('span',{key:property.propertyName},[_vm._v(" "+_vm._s(property.propertyName)+" "+_vm._s(_vm.getModelPropertyType(property))+" "),(property.propertyType.isKey)?_c('v-btn',{attrs:{"icon":"","color":"gold"}},[_c('v-icon',[_vm._v("mdi-key")])],1):_vm._e()],1)})],2)],1),_c('v-card-actions',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"blue darken-3"},nativeOn:{"click":function($event){return _vm.editModel(model.entityId)}}},on),[_c('v-icon',[_vm._v("mdi-file-edit")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit this model")])]),_c('div',{staticStyle:{"position":"absolute","bottom":"15px","right":"15px"}},[_c('v-btn',{attrs:{"icon":"","color":"red"},nativeOn:{"click":function($event){return _vm.deleteModel(model.entityId)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1)],1)],1)}),_vm._l((this.queries),function(query){return _c('v-expansion-panel',{key:query.name,on:{"click":function($event){return _vm.selectQuery(query.entityId)}}},[_c('v-expansion-panel-header',{staticClass:"body-1 mb-1",style:(_vm.selectedQueryId() == query.entityId
                ? 'color:green'
                : 'color:black')},[_vm._v(_vm._s(_vm.trimLongEntityName(query.name))+" ("+_vm._s(_vm.assignedTemplateCountForModel(query.entityId))+") "),(query.isCommand)?_c('div',{staticStyle:{"display":"inline","max-width":"80px"}},[_c('v-chip',{staticClass:"mx-2",attrs:{"color":"orange","small":"","text-color":"white"}},[_vm._v("C ")])],1):_vm._e(),(!query.isCommand)?_c('div',{staticStyle:{"display":"inline","max-width":"80px"}},[_c('v-chip',{staticClass:"mx-2",attrs:{"color":"red","small":"","text-color":"white"}},[_vm._v("Q ")])],1):_vm._e()]),_c('v-expansion-panel-content',[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[_vm._v(" "+_vm._s(query.name)+" "),_c('div',[_vm._v(" Request Model "),_c('br'),_vm._l((_vm.getRequestQueryDisplay(query.request)),function(property){return _c('v-chip',{key:query.name + property,staticClass:"ma-2",attrs:{"small":"","text-color":"white","color":"blue"}},[_vm._v(_vm._s(property)+" ")])})],2),_c('div',[_vm._v(" Response Model "),_c('br'),_c('v-chip',{staticClass:"ma-2",attrs:{"small":"","text-color":"white","color":"blue"}},[_vm._v(" "+_vm._s(_vm.getQueryDisplay(query.response))+" ")])],1)])],1),_c('v-card-actions',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"blue darken-3"},nativeOn:{"click":function($event){return _vm.editQuery(query.entityId)}}},on),[_c('v-icon',[_vm._v("mdi-file-edit")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit this query/command")])]),_c('div',{staticStyle:{"position":"absolute","bottom":"15px","right":"15px"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"red"},nativeOn:{"click":function($event){return _vm.deleteQuery(query.entityId)}}},on),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete this query/command")])])],1)],1)],1)],1)})],2)],1),_c('v-col',{key:2},[_c('v-text-field',{attrs:{"label":"Assigned","placeholder":"Filter"},model:{value:(_vm.assignedFilter),callback:function ($$v) {_vm.assignedFilter=$$v},expression:"assignedFilter"}}),_c('v-expansion-panels',_vm._l((this.assignedTemplates),function(template){return _c('v-expansion-panel',{key:template.displayName},[_c('v-expansion-panel-header',{staticClass:"body-1 mb-1"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('div',_vm._g({},on),[_vm._v(" "+_vm._s(_vm.trimLongEntityName(template.displayName))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(template.displayName))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-btn',_vm._g({staticStyle:{"position":"absolute","right":"60px"},attrs:{"icon":"","color":"red"},on:{"click":function($event){return _vm.unassignTemplate(template.templateId)}}},on),[_c('v-icon',[_vm._v("mdi-arrow-right-bold")])],1)]}}],null,true)},[_c('span',[_vm._v("Unassign this template")])])],1),_c('v-expansion-panel-content',[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(template.description)+" ")]),_c('v-list-item-subtitle',_vm._l((template.technology),function(tech){return _c('v-chip',{key:tech,staticClass:"ma-2",attrs:{"x-small":"","color":"primary"}},[_vm._v(" "+_vm._s(tech)+" ")])}),1)],1)],1)],1)],1)}),1)],1),_c('v-col',{key:3},[_c('v-text-field',{attrs:{"label":"Available","placeholder":"Filter"},model:{value:(_vm.unassignedFilter),callback:function ($$v) {_vm.unassignedFilter=$$v},expression:"unassignedFilter"}}),_c('v-expansion-panels',_vm._l((this.unassignedTemplates),function(template){return _c('v-expansion-panel',{key:template.displayName},[_c('v-expansion-panel-header',{staticClass:"body-1 mb-1"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-btn',_vm._g({staticStyle:{"position":"absolute","right":"60px"},attrs:{"icon":"","color":"green"},on:{"click":function($event){return _vm.assignTemplate(template.templateId)}}},on),[_c('v-icon',[_vm._v("mdi-arrow-left-bold")])],1)]}}],null,true)},[_c('span',[_vm._v("Assign this template")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('div',_vm._g({},on),[_vm._v(" "+_vm._s(_vm.trimLongEntityName(template.displayName))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(template.displayName))])])],1),_c('v-expansion-panel-content',[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(template.description)+" ")]),_c('v-list-item-subtitle',_vm._l((template.technology),function(tech){return _c('v-chip',{key:tech,staticClass:"ma-2",attrs:{"x-small":"","color":"primary"}},[_vm._v(" "+_vm._s(tech)+" ")])}),1)],1)],1)],1)],1)}),1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }