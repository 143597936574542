


































import { Component, Prop, Vue } from "vue-property-decorator";
import store from "../store/index";
import { Actions, Mutations } from "../store/StoreActions";
import { ApiModels } from "../ApiModels/DataModel";
import HelpButton from './Shared/HelpButton.vue'

@Component({
    components: {
        'v-help-button': HelpButton
    }
})

export default class extends Vue {
  public props = {
    id: ""
  };

  private name = "";
  private id = "";
  private environmentVariables = "";
  private editMode = false;
  private rules = {
    required: (value: string) => !!value || "Required."
  };

  mounted() {
    (this.$refs["name"] as any).focus();
    if (this.$attrs.id != null) {
      this.editMode = true;
      this.id = this.$attrs.id;
      const project = this.$store.getters.getProjectById(this.id);
      this.name = project.name;
      this.environmentVariables = project.environmentVariables;
    }
  }

  submit() {
    if ((this.$refs.form as any).validate()) {
      if (this.editMode) {
        const project = this.$store.getters.getProjectById(this.id);
        project.name = this.name;
        project.environmentVariables = this.environmentVariables;
        this.$store.commit(Mutations.UPDATE_PROJECT, project);
        this.$store.dispatch(Actions.SAVE_PROJECT, this.id);
        this.$router.push("/projects");
      } else {
        const project = new ApiModels.Project();
        project.name = this.name;
        project.environmentVariables = this.environmentVariables;
        this.$store.commit(Mutations.CREATE_PROJECT, project);
        this.$store.dispatch(
          Actions.SAVE_PROJECT,
          this.$store.getters.getProjectByName(this.name).id
        );
        this.$router.push("/workspace");
      }
    }
  }
}
