import Vue from "vue";
import Vuex from "vuex";
import { GetterTree, MutationTree, ActionTree } from "vuex";
import { ApiModels } from "@/ApiModels/DataModel";
import { Mutations, Actions } from "./StoreActions";
import { v4 as uuidv4 } from "uuid";
import {GlobalFunctions} from '../GlobalFunctions'
Vue.use(Vuex);

// still not seeing good types from getters. This is a start.
/*

interface Getters {
  projects: any;
  getLoadingProjects:any;
  getAuthToken:any;
  getModelsForSelectedProject:Array<ApiModels.Model>;
  getProfile:any;
}


type GettersDefinition = {
  [P in keyof Getters]: (state: ApiModels.DataModel, getters: Getters) => Getters[P];
}
*/

const getters: GetterTree<ApiModels.DataModel, any> = {
  projects: state => {
    return state.projects.filter(x => !x.markedForDeletion);
  },
  getLoadingProjects: state => {
    return state.loadingProjects;
  },
  getAuthToken: state => {
    const profileString = window.localStorage.getItem("profile");
    if (profileString != null) {
      const profile = (profileString as unknown) as ApiModels.AuthResponse;
      return profile.token;
    } else {
      return "";
    }
  },
  getProfile: state => {
    const profileString = window.localStorage.getItem("profile");
    if (profileString != null) {
      const profile = (profileString as unknown) as ApiModels.AuthResponse;
      return profile;
    } else {
      return new ApiModels.AuthResponse();
    }
  },
  getIsUserAuthenticated: state => (name: string) => {
    const profile = window.localStorage.getItem("profile");
    if (profile != null) {
      return true;
    } else {
      return false;
    }
  },
  getProjectByName: state => (name: string) => {
    return state.projects.find(x => x.name == name);
  },
  getProjectById: state => (id: string) => {
    return state.projects.find(x => x.id == id);
  },
  getTemplateById: (state, templateId) => (templateId: string) => {
    return state.templates.find(x => x.templateId == templateId);
  },
  getJobs: state => {
    return state.jobs;
  },
  getQuestions: state => {
    return state.questions;
  },
  getMyJobs: state => {
    return state.myJobs;
  },
  getSetupSelected: state => {
    return state.setupSelected;
  },
  getModelByNameFromSelectedProject: state => (name: string) => {
    const project = state.projects.find(x => x.id == state.selectedProjectId);
    return project?.models.find(x => x.name == name);
  },
  getModelsForSelectedProject: state => () => {
    const project = state.projects.find(x => x.id == state.selectedProjectId);
    if (project == null) {
      return new Array<ApiModels.Model>();
    } else {
      return project.models;
    }
  },
  getModelByIdFromSelectedProject: state => (id: string) => {
    const project = state.projects.find(x => x.id == state.selectedProjectId);
    return project?.models.find(x => x.entityId == id);
  },
  getRouteCollectionByIdFromSelectedProject: state => (id: string) => {
    const project = state.projects.find(x => x.id == state.selectedProjectId);
    return project?.routeCollections.find(x => x.id == id);
  },
  getLatestAddedRouteCollectionForSelectedProject: state => {
    const project = state.projects.find(x => x.id == state.selectedProjectId);
    const lastRouteCollection = project?.routeCollections.slice(-1)[0];
    if (lastRouteCollection == null) {
      return "";
    } else {
      return lastRouteCollection.id;
    }
  },
  getRouteCollectionIdForRouteForSelectedProject: state => (
    routeId: string
  ) => {
    const project = state.projects.find(x => x.id == state.selectedProjectId);
    let id = "";
    project?.routeCollections.forEach(x => {
      const route = x.routes.find(r => r.id == routeId);
      if (route != null) {
        id = x.id;
      }
    });

    return id;
  },
  getRouteBaseRoute: state => (routeId: string) => {
    const project = state.projects.find(x => x.id == state.selectedProjectId);
    let routeCollection = new ApiModels.RouteCollection();
    project?.routeCollections.forEach(x => {
      const route = x.routes.find(r => r.id == routeId);
      if (route != null) {
        routeCollection = x;
      }
    });

    return routeCollection.baseRoute;
  },
  getRouteCollectionForSelectedProject: state => (id: string) => {
    const project = state.projects.find(x => x.id == state.selectedProjectId);
    return project?.routeCollections;
  },
  getRouteByIdFromSelectedProject: state => (routeId: string) => {
    const project = state.projects.find(x => x.id == state.selectedProjectId);
    let route = new ApiModels.GenerationRouteDefinition();
    project?.routeCollections.forEach(x => {
      const testRoute = x.routes.find(r => r.id == routeId);
      if (testRoute != null) {
        route = testRoute;
      }
    });

    return route;
  },
  getQueryByIdFromSelectedProject: state => (queryId: string) => {
    const project = state.projects.find(x => x.id == state.selectedProjectId);
    return project?.queryContainer.queries.find(x => x.entityId == queryId);
  },
  getQueriesForSelectedProject: state => {
    const project = state.projects.find(x => x.id == state.selectedProjectId);
    return project?.queryContainer.queries;
  },
  getSelectedProjectRouteCollections: (
    state
  ): Array<ApiModels.RouteCollection> => {
    const project = state.projects.find(x => x.id == state.selectedProjectId);
    if (project == null) {
      return [];
    } else {
      return project.routeCollections;
    }
  },
  getParseResult: state => {
    return state.parseResult;
  },
  getAssignedTemplateCountForModel: state => (modelId: string) => {
    const project = state.projects.find(x => x.id == state.selectedProjectId);
    const model = project?.models.find(m => m.entityId == modelId);
    if (project != null) {
      return project.projectTemplates.filter(x => x.modelId == modelId).length;
    } else {
      return 0;
    }
  },
  assignedTemplates: (state): Array<ApiModels.Template> => {
    const project = state.projects.find(x => x.id == state.selectedProjectId);

    let entityId = "";

    const model = project?.models.find(
      m => m.entityId == state.selectedModelId
    );
    if (model == null) {
      const route = project?.routeCollections.find(
        m => m.id == state.selectedRouteCollectionId
      );
      if (route != null) {
        entityId = route.id;
      } else {
        const query = project?.queryContainer.queries.find(
          q => q.entityId == state.selectedQueryId
        );
        if (query != null) {
          entityId = query.entityId;
        } else {
          if (state.setupSelected && project?.setupModel != null) {
            entityId = project?.setupModel.id;
          }
        }
      }
    } else {
      entityId = model.entityId;
    }

    const templates = Array<ApiModels.Template>();

    if (project != null) {
      state.templates.forEach(t => {
        if (
          project.projectTemplates.findIndex(
            pt => pt.templateId == t.templateId && pt.modelId == entityId
          ) > -1
        ) {
          templates.push(t);
        }
      });
    }
    return templates;
  },
  unassignedTemplates: (state): Array<ApiModels.Template> => {
    enum EnumTemplateType {
      None,
      Model,
      Route,
      Query,
      Project
    }

    let templateType = EnumTemplateType.None;
    const project = state.projects.find(x => x.id == state.selectedProjectId);
    let entityId = "";
    const model = project?.models.find(
      m => m.entityId == state.selectedModelId
    );

    if (model == null) {
      const route = project?.routeCollections.find(
        m => m.id == state.selectedRouteCollectionId
      );

      if (route != null) {
        entityId = route.id;
        templateType = EnumTemplateType.Route;
      } else {
        const query = project?.queryContainer.queries.find(
          q => q.entityId == state.selectedQueryId
        );
        if (query != null) {
          entityId = query.entityId;
          templateType = EnumTemplateType.Query;
        } else {
          if (state.setupSelected && project?.setupModel != null) {
            entityId = project?.setupModel.id;
            templateType = EnumTemplateType.Project;
          }
        }
      }
    } else {
      entityId = model.entityId;
      templateType = EnumTemplateType.Model;
    }

    if (templateType == EnumTemplateType.None) {
      throw new Error("Unable to determine template type");
    }

    const templates = Array<ApiModels.Template>();

    if (project != null) {
      state.templates.forEach(t => {
        if (
          project.projectTemplates.findIndex(
            pt => pt.templateId == t.templateId && pt.modelId == entityId
          ) == -1
        ) {
          if (templateType == EnumTemplateType.Model) {
            if (
              t.modelTypeId.toLowerCase() ==
              "4ee0b2f1-4deb-4a82-959c-2f3f4c7af980"
            ) {
              templates.push(t);
            }
          } else if (templateType == EnumTemplateType.Route) {
            if (
              t.modelTypeId.toLowerCase() ==
              "7ee0b2f1-4deb-4a82-959c-2f3f4c7af980"
            ) {
              templates.push(t);
            }
          } else if (templateType == EnumTemplateType.Query) {
            if (
              t.modelTypeId.toLowerCase() ==
              "539522ab-b217-4db0-9429-8c262450f5b2"
            ) {
              templates.push(t);
            }
          } else if (templateType == EnumTemplateType.Project) {
            if (
              t.modelTypeId.toLowerCase() ==
              "88ebd8fd-f87a-481f-b5ca-a6d0db22dd53"
            ) {
              templates.push(t);
            }
          } else {
            throw new Error("Unhandled...");
          }
        }
      });
    }
    return templates;
  },
  modelTypes: state => {
    return state.modelTypes;
  },
  selectedProject: state => {
    return state.projects.find(x => x.id == state.selectedProjectId);
  },
  selectedProjectModels: state => {
    const project = state.projects.find(x => x.id == state.selectedProjectId);
    if (project == null) {
      return [];
    } else {
      return project.models;
    }
  },
  selectedModel: state => {
    const project = state.projects.find(x => x.id == state.selectedProjectId);
    if (project != null) {
      const model = project.models.find(
        x => x.entityId == state.selectedModelId
      );
      return model;
    }
  },
  getexecuteToolResult: state => {
    return state.executeToolResult;
  }
};

const mutations: MutationTree<ApiModels.DataModel> = {
  [Mutations.SET_AUTH_TOKEN]: (state, authResponse: ApiModels.AuthResponse) => {
    window.localStorage.setItem("profile", JSON.stringify(authResponse));
  },
  [Mutations.PROJECT_SAVED]: state => {
    GlobalFunctions.logDebug("project saved...");
  },
  [Mutations.SET_SELECTIONS]: (state, parameters) => {
    state = Object.assign(state, parameters);
    window.localStorage.setItem("selectedProject", state.selectedProjectId);
  },
  [Mutations.SET_LOADING_PROJECTS]: (state, value) => {
    state.loadingProjects = value;
  },
  [Mutations.SET_JOBS]: (state, response) => {
    state.jobs = response.jobPostings;
  },
  [Mutations.SET_QUESTIONS]: (state, response) => {
    state.questions = response;
  },
  [Mutations.SET_MY_JOBS]: (state, response) => {
    state.myJobs = response.jobPostings;
  },
  [Mutations.ASSIGN_TEMPLATE_TO_SETUP]: (state, templateId) => {
    const project = state.projects.find(x => x.id == state.selectedProjectId);
    if (project != null) {
      if (project.projectTemplates == null) {
        project.projectTemplates = [];
      }
      const projectTemplate = new ApiModels.ProjectTemplate();
      projectTemplate.modelId = project.setupModel.id;
      projectTemplate.templateId = templateId;
      project.projectTemplates = [...project.projectTemplates, projectTemplate];
    }
  },
  [Mutations.UNASSIGN_TEMPLATE_FROM_SETUP]: (state, templateId) => {
    const project = state.projects.find(x => x.id == state.selectedProjectId);
    if (project != null) {
      project.projectTemplates = [
        ...project.projectTemplates.filter(item => {
          return (
            (item.templateId != templateId &&
              item.modelId == project.setupModel.id) ||
            item.modelId != project.setupModel.id
          );
        })
      ];
    }
  },
  [Mutations.ASSIGN_TEMPLATE_TO_MODEL]: (state, templateId) => {
    const project = state.projects.find(x => x.id == state.selectedProjectId);
    if (project != null) {
      const model = project.models.find(
        x => x.entityId == state.selectedModelId
      );
      if (model != null) {
        if (project.projectTemplates == null) {
          project.projectTemplates = [];
        }
        const projectTemplate = new ApiModels.ProjectTemplate();
        projectTemplate.modelId = model.entityId;
        projectTemplate.templateId = templateId;
        project.projectTemplates = [
          ...project.projectTemplates,
          projectTemplate
        ];
      }
    }
  },
  [Mutations.UNASSIGN_TEMPLATE_FROM_MODEL]: (state, templateId) => {
    const project = state.projects.find(x => x.id == state.selectedProjectId);
    if (project != null) {
      const model = project.models.find(
        x => x.entityId == state.selectedModelId
      );
      if (model != null) {
        project.projectTemplates = [
          ...project.projectTemplates.filter(item => {
            return (
              (item.templateId != templateId &&
                item.modelId == model.entityId) ||
              item.modelId != model.entityId
            );
          })
        ];
      }
    }
  },
  [Mutations.ASSIGN_TEMPLATE_TO_ROUTE_COLLECTION]: (state, templateId) => {
    const project = state.projects.find(x => x.id == state.selectedProjectId);
    if (project != null) {
      const routeCollection = project.routeCollections.find(
        x => x.id == state.selectedRouteCollectionId
      );
      if (routeCollection != null) {
        if (project.projectTemplates == null) {
          project.projectTemplates = [];
        }
        const projectTemplate = new ApiModels.ProjectTemplate();
        projectTemplate.modelId = routeCollection.id;
        projectTemplate.templateId = templateId;
        project.projectTemplates = [
          ...project.projectTemplates,
          projectTemplate
        ];
      }
    }
  },
  [Mutations.UNASSIGN_TEMPLATE_FROM_ROUTE_COLLECTION]: (state, templateId) => {
    const project = state.projects.find(x => x.id == state.selectedProjectId);
    if (project != null) {
      const route = project.routeCollections.find(
        x => x.id == state.selectedRouteCollectionId
      );
      if (route != null) {
        project.projectTemplates = [
          ...project.projectTemplates.filter(item => {
            return (
              (item.templateId != templateId && item.modelId == route.id) ||
              item.modelId != route.id
            );
          })
        ];
      }
    }
  },
  [Mutations.ASSIGN_TEMPLATE_TO_QUERY]: (state, templateId) => {
    const project = state.projects.find(x => x.id == state.selectedProjectId);
    if (project != null) {
      const query = project.queryContainer.queries.find(
        x => x.entityId == state.selectedQueryId
      );
      if (query != null) {
        if (project.projectTemplates == null) {
          project.projectTemplates = [];
        }
        const projectTemplate = new ApiModels.ProjectTemplate();
        projectTemplate.modelId = query.entityId;
        projectTemplate.templateId = templateId;
        project.projectTemplates = [
          ...project.projectTemplates,
          projectTemplate
        ];
      }
    }
  },
  [Mutations.UNASSIGN_TEMPLATE_FROM_QUERY]: (state, templateId) => {
    const project = state.projects.find(x => x.id == state.selectedProjectId);
    if (project != null) {
      const query = project.queryContainer.queries.find(
        x => x.entityId == state.selectedQueryId
      );
      if (query != null) {
        project.projectTemplates = [
          ...project.projectTemplates.filter(item => {
            return (
              (item.templateId != templateId &&
                item.modelId == query.entityId) ||
              item.modelId != query.entityId
            );
          })
        ];
      }
    }
  },
  [Mutations.CREATE_PROJECT]: (state, project) => {
    const id = uuidv4();
    project.id = id;
    state.selectedProjectId = id;
    state.projects.push({ ...project });
  },
  [Mutations.UPDATE_PROJECT]: (state, project) => {
    state.projects = [
      ...state.projects.filter(item => {
        return item.id != project.id;
      }),
      project
    ];
  },
  [Mutations.DELETE_PROJECT]: (state, projectId) => {
    const project = state.projects.find(x => x.id == projectId);
    const projectIndex = state.projects.findIndex(x => x.id == projectId);
    if (project != null) {
      project.markedForDeletion = true;
      state.projects.splice(projectIndex, 1, project);
      if (project.id == state.selectedProjectId) {
        state.selectedProjectId = "";
      }
    }
  },
  [Mutations.SET_PROJECTS]: (state, profile) => {
    state.projects = [...profile.projects];

    const project = state.projects.find(x => x.id == state.selectedProjectId);
    if (project != null) {
      if (project.models.length > 0) {
        state.selectedModelId = project.models[0].entityId;
      }
    }
  },
  [Mutations.CREATE_ROUTE]: (state, payload) => {
    GlobalFunctions.logDebug(
      "creating route with collection id=" + payload.routeCollectionId
    );
    const project = state.projects.find(x => x.id == state.selectedProjectId);

    if (project != null) {
      const routeCollection = project!.routeCollections.find(
        x => x.id == payload.routeCollectionId
      );
      GlobalFunctions.logDebug(
        "route collection found with id=" +
          routeCollection?.id +
          " with name=" +
          routeCollection?.name
      );
      const route = new ApiModels.GenerationRouteDefinition();
      route.httpVerb = payload.route.httpVerb;
      route.id = uuidv4();
      route.queryId = payload.route.queryId;
      route.response = payload.route.response;
      route.route = payload.route.route;
      routeCollection!.routes = [...routeCollection!.routes, route];
      GlobalFunctions.logDebug(
        "route created with collection id=" +
          payload.routeCollectionId +
          " and id=" +
          route.id
      );
      routeCollection!.routes.forEach(x => {
        GlobalFunctions.logDebug("current routes id=" + x.id);
      });
      project.routeCollections = [
        ...project.routeCollections.filter(item => {
          return item.id != routeCollection!.id;
        }),
        routeCollection
      ] as Array<ApiModels.RouteCollection>;
    }
  },
  [Mutations.UPDATE_ROUTE]: (state, payload) => {
    const project = state.projects.find(x => x.id == state.selectedProjectId);
    const routeCollection = project!.routeCollections.find(
      x => (x.id = payload.routeCollectionId)
    );
    routeCollection!.routes = [
      ...routeCollection!.routes.filter(item => {
        return item.id != payload.route.id;
      }),
      payload.route
    ];
  },
  [Mutations.SET_DEMO_PROJECTS]: (state, payload) => {
    state.demoProjects = payload;
  },
  [Mutations.DELETE_ROUTE]: (state, routeId) => {
    const project = state.projects.find(x => x.id == state.selectedProjectId);
    let routeCollectionId = "";
    project?.routeCollections.forEach(x => {
      const testRoute = x.routes.find(r => r.id == routeId);
      if (testRoute != null) {
        routeCollectionId = x.id;
      }
    });

    const routeCollection = project!.routeCollections.find(
      x => x.id == routeCollectionId
    );
    routeCollection!.routes = [
      ...routeCollection!.routes.filter(item => {
        return item.id != routeId;
      })
    ];
  },
  [Mutations.CREATE_ROUTE_COLLECTION]: (state, parameters) => {
    const project = state.projects.find(x => x.id == state.selectedProjectId);
    const routeCollection = new ApiModels.RouteCollection();

    routeCollection.id = uuidv4();
    routeCollection.name = parameters.name;
    routeCollection.baseRoute = parameters.baseRoute;
    routeCollection.routes = [...parameters.routes];

    if (project != null) {
      if (project.routeCollections == null) {
        project.routeCollections = [];
      }
      project.routeCollections = [...project.routeCollections, routeCollection];
    }
    GlobalFunctions.logDebug("creating route collection with id=" + routeCollection.id);
  },
  [Mutations.UPDATE_ROUTE_COLLECTION]: (state, routeCollection) => {
    const project = state.projects.find(x => x.id == state.selectedProjectId);
    if (project != null) {
      project.routeCollections = [
        ...project.routeCollections.filter(item => {
          return item.id != routeCollection.id;
        }),
        routeCollection
      ];
    }
  },
  [Mutations.DELETE_ROUTE_COLLECTION]: (state, routeCollectionId) => {
    const project = state.projects.find(x => x.id == state.selectedProjectId);
    if (project != null) {
      project.routeCollections = [
        ...project.routeCollections.filter(item => {
          return item.id != routeCollectionId;
        })
      ];

      project.projectTemplates = [
        ...project.projectTemplates.filter(item => {
          return item.modelId != routeCollectionId;
        })
      ];
    }
  },
  [Mutations.CREATE_MODEL]: (state, model: ApiModels.Model) => {
    const project = state.projects.find(x => x.id == state.selectedProjectId);
    model.entityId = uuidv4();
    model.parsedModel.entityId = model.entityId;
    if (project != null) {
      if (project.models == null) {
        project.models = [];
      }
      project.models = [...project.models, model];
      state.selectedModelId = model.entityId;
    }
  },
  [Mutations.UPDATE_MODEL]: (state, model) => {
    const project = state.projects.find(x => x.id == state.selectedProjectId);
    if (project != null) {
      project.models = [
        ...project.models.filter(item => {
          return item.entityId != model.entityId;
        }),
        model
      ];
    }
  },
  [Mutations.DELETE_MODEL]: (state, modelId) => {
    const project = state.projects.find(x => x.id == state.selectedProjectId);
    if (project != null) {
      project.models = [
        ...project.models.filter(item => {
          return item.entityId != modelId;
        })
      ];

      project.projectTemplates = [
        ...project.projectTemplates.filter(item => {
          return item.modelId != modelId;
        })
      ];
    }
  },
  [Mutations.CREATE_QUERY]: (state, query) => {
    const project = state.projects.find(x => x.id == state.selectedProjectId);

    if (query.isCommand) {
      if (!(query.name.toUpperCase().indexOf("COMMAND") > -1)) {
        query.name += "Command";
      }
    } else {
      if (!(query.name.toUpperCase().indexOf("QUERY") > -1)) {
        query.name += "Query";
      }
    }

    query.entityId = uuidv4();
    if (project != null) {
      project.queryContainer.queries = [
        ...project.queryContainer.queries,
        query
      ];
    }
  },
  [Mutations.UPDATE_QUERY]: (state, query) => {
    const project = state.projects.find(x => x.id == state.selectedProjectId);

    if (query.isCommand) {
      if (!(query.name.toUpperCase().indexOf("COMMAND") > -1)) {
        query.name += "Command";
      }
    } else {
      if (!(query.name.toUpperCase().indexOf("QUERY") > -1)) {
        query.name += "Query";
      }
    }

    if (project != null) {
      project.queryContainer.queries = [
        ...project.queryContainer.queries.filter(item => {
          return item.entityId != query.entityId;
        }),
        query
      ];
    }
  },
  [Mutations.DELETE_QUERY]: (state, queryId) => {
    const project = state.projects.find(x => x.id == state.selectedProjectId);
    if (project != null) {
      project.queryContainer.queries = [
        ...project.queryContainer.queries.filter(item => {
          return item.entityId != queryId;
        })
      ];

      project.projectTemplates = [
        ...project.projectTemplates.filter(item => {
          return item.modelId != queryId;
        })
      ];
    }
  },
  [Mutations.UPDATE_MODEL_WITH_PARSE_PARAMETERS_RESULT]: (
    state,
    parameters
  ) => {
    state.parseResult = parameters.parseResult;

    const project = state.projects.find(x => x.id == state.selectedProjectId);

    state.parseResult.modelContainer.models.forEach(x => {
      const model = project!.models.find(model => model.name == x.entityName);
      if (model == null) {
        const newModel = new ApiModels.Model();
        newModel.name = x.entityName || parameters.name;
        newModel.parsedModel = x;
        newModel.entityId = x.entityId;
        project!.models.push(newModel);
      } else {
        model.parsedModel = x;
        project!.models = [
          ...project!.models.filter(item => {
            return item.entityId != model.entityId;
          }),
          model
        ];
      }
    });

    state.parseResult.queryContainer.queries.forEach(x => {
      const query = project!.queryContainer.queries.find(
        query => query.name == x.name
      );
      if (query == null) {
        project!.queryContainer.queries.push(x);
      } else {
        project!.queryContainer.queries = [
          ...project!.queryContainer.queries.filter(item => {
            return item.entityId != x.entityId;
          }),
          x
        ];
      }
    });

    state.parseResult.routeCollections.forEach(x => {
      const project = state.projects.find(x => x.id == state.selectedProjectId);
      if (project!.routeCollections == null) {
        project!.routeCollections = [];
      }
      const routeCollection = project!.routeCollections.find(
        routeCollection => routeCollection.name == x.name
      );

      if (routeCollection == null) {
        const newRouteCollection = new ApiModels.RouteCollection();
        newRouteCollection.baseRoute = x.baseRoute;
        newRouteCollection.name = x.name;
        newRouteCollection.id = uuidv4();
        newRouteCollection.entityId = x.entityId;
        x.routes.forEach(r => {
          const newRoute = new ApiModels.GenerationRouteDefinition();
          newRoute.httpVerb = r.httpVerb;
          newRoute.id = uuidv4();
          newRoute.route = r.route;
          newRoute.queryId = r.queryId;
          newRoute.entityId = r.entityId;
          newRouteCollection.routes.push(newRoute);
        });

        project!.routeCollections = [
          ...project!.routeCollections,
          newRouteCollection
        ];
      }
    });

    state.projects = [
      ...state.projects.filter(item => {
        return item.id != project!.id;
      }),
      project!
    ];

    if (project!.models.length > 0) {
      state.selectedModelId = project!.models[0].entityId;
    }
  },
  [Mutations.SET_TEMPLATES]: (state, templates) => {
    state.templates = [...templates];
  },
  [Mutations.SET_GENERATION_RESULT]: (state, generationResult) => {
    const project = state.projects.find(x => x.id == state.selectedProjectId);
    if (project != null) {
      project.generationResult = [...generationResult];
    }
  },
  [Mutations.CLEAR_GENERATION_RESULT]: state => {
    const project = state.projects.find(x => x.id == state.selectedProjectId);
    if (project != null) {
      project.generationResult = [];
    }
  },
  [Mutations.SET_EXECUTE_TOOL_RESULT]: (state, result) => {
    state.executeToolResult = result;
  },
  [Mutations.SELECT_SETUP_MODEL]: state => {
    state.setupSelected = true;
  },
  [Mutations.DESELECT_SETUP_MODEL]: state => {
    state.setupSelected = false;
  },
  [Mutations.SET_CURRENT_SWAGGER_URL]: (state, url) => {
    state.currentSwaggerUrl = url;
  },
  [Mutations.ADD_TEST_MESSAGES]: (state, message) => {
    state.testMessages = [...state.testMessages, message];
  },
  [Mutations.CLEAR_TEST_MESSAGES]: state => {
    state.testMessages = [];
  }
};

const actions: ActionTree<ApiModels.DataModel, any> = {
  [Actions.SAVE_PROJECTS]: context => {

    if (!context.getters.getIsUserAuthenticated()) {
      return;
    }

    const profile = new ApiModels.Profile();
    profile.projects = [...context.state.projects];
    Vue.axios
      .post("profile", profile)
      .then(response => {
        context.commit(Mutations.PROJECT_SAVED);
      })
  },
  [Actions.SAVE_PROJECT]: (context, projectId) => {
    const project = context.state.projects.find(x => x.id == projectId);
    if (!context.getters.getIsUserAuthenticated()) {
      return;
    }
    if (project!.isDemo) {
      return;
    }
    Vue.axios
      .post("profile/saveproject", project)
      .then(response => {
        context.commit(Mutations.PROJECT_SAVED);
      })
  },
  [Actions.SAVE_CURRENT_PROJECT]: context => {
    const project = context.state.projects.find(
      x => x.id == context.state.selectedProjectId
    );
    if (!context.getters.getIsUserAuthenticated()) {
      return;
    }
    if (project!.isDemo) {
      return;
    }
    Vue.axios
      .post("profile/saveproject", project)
      .then(response => {
        context.commit(Mutations.PROJECT_SAVED);
      })
  },
  [Actions.LOAD_PROJECTS]: context => {
    if (!context.getters.getIsUserAuthenticated()) {
      return;
    }
    context.commit(Mutations.SET_LOADING_PROJECTS, true);

    Vue.axios
      .get("profile")
      .then(response => {
        context.commit(Mutations.SET_PROJECTS, response.data);
        context.commit(
          Mutations.SET_SELECTIONS,
          Object.assign(context.state, {
            selectedProjectId: window.localStorage.getItem("selectedProject")
          })
        );
        context.commit(Mutations.SET_LOADING_PROJECTS, false);
      })
      .catch(e => {
        GlobalFunctions.logError(e);
        context.commit(Mutations.SET_LOADING_PROJECTS, false);
      });
  },
  [Actions.LOAD_DEMO]: context => {
    context.commit(Mutations.SET_LOADING_PROJECTS, true);

    Vue.axios
      .get("profile/demoprojects")
      .then(response => {
        context.commit(Mutations.SET_DEMO_PROJECTS, response.data);
        context.commit(Mutations.SET_LOADING_PROJECTS, false);
      })
      .catch(e => {
        GlobalFunctions.logError(e);
        context.commit(Mutations.SET_LOADING_PROJECTS, false);
      });
  },
  [Actions.LOAD_ALL_DEMOS]: context => {
    context.commit(Mutations.SET_LOADING_PROJECTS, true);

    Vue.axios
      .get("profile/alldemoprojects")
      .then(response => {
        context.commit(Mutations.SET_DEMO_PROJECTS, response.data);
        context.commit(Mutations.SET_LOADING_PROJECTS, false);
      })
      .catch(e => {
        GlobalFunctions.logError(e);
        context.commit(Mutations.SET_LOADING_PROJECTS, false);
      });
  },

  [Actions.LOAD_TEMPLATES]: context => {
    Vue.axios
      .get("profile/templates")
      .then(response => {
        context.commit(Mutations.SET_TEMPLATES, response.data);
      });
  },
  [Actions.LOAD_JOBS]: context => {
    Vue.axios
      .get("jobpostings")
      .then(response => {
        context.commit(
          Mutations.SET_JOBS,
          response.data as ApiModels.JobsResponse
        );
      });
  },
  [Actions.LOAD_QUESTIONS]: context => {
    Vue.axios
      .get("jobpostings/questions")
      .then(response => {
        context.commit(
          Mutations.SET_QUESTIONS,
          response.data as Array<ApiModels.Question>
        );
      });
  },
  [Actions.LOAD_MY_JOBS]: context => {
    Vue.axios
      .get("jobpostings/myjobs")
      .then(response => {
        context.commit(
          Mutations.SET_MY_JOBS,
          response.data as ApiModels.JobsResponse
        );
      });
  },
  [Actions.CREATE_JOB]: (context, jobPosting) => {
    Vue.axios.post("jobpostings", jobPosting);
  },
  [Actions.REQUEST_A_TOOL]: (context, model) => {
    Vue.axios.post("tools/requestatool", model);
  },
  [Actions.GENERATE_PROJECT]: context => {
    const project = context.state.projects.find(
      x => x.id == context.state.selectedProjectId
    );
    project!.generationResult = [];
    Vue.axios
      .post("profile/generate", project)
      .then(response => {
        context.commit(Mutations.SET_GENERATION_RESULT, response.data);
      });
  },
  [Actions.DOWNLOAD_PROJECT]: context => {
    const project = context.state.projects.find(
      x => x.id == context.state.selectedProjectId
    );
    project!.generationResult = [];
    Vue.axios
      .post("profile/generatezip", project, {
        responseType: "blob"
      })
      .then(response => {
        const blob = new Blob([response.data], {
          type: "application/octet-stream"
        });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", project?.name + ".zip");
        document.body.appendChild(link);
        link.click();
      });
  },
  [Actions.AUTHENTICATE]: (context, code) => {
    Vue.axios
      .post("authenticate", { code: code })
      .then(response => {
        const authResponse = response.data as ApiModels.AuthResponse;
        context.commit(Mutations.SET_AUTH_TOKEN, authResponse);
        window.location.reload();
      });
  },
  [Actions.EXECUTE_TOOL]: (context, parameters) => {
    Vue.axios
      .post(parameters.route, parameters.input, { transformResponse: [] })
      .then(response => {
        context.commit(Mutations.SET_EXECUTE_TOOL_RESULT, response.data);
      })
      .catch(e => {
        GlobalFunctions.logError(e);
        context.commit(Mutations.SET_EXECUTE_TOOL_RESULT, e.response.data);
      });
  },
  [Actions.EXECUTE_JMES_TOOL]: (context, parameters) => {
    Vue.axios
      .post("tools/jmes", parameters, { transformResponse: [] })
      .then(response => {
        context.commit(Mutations.SET_EXECUTE_TOOL_RESULT, response.data);
      })
      .catch(e => {
        GlobalFunctions.logError(e);
        context.commit(Mutations.SET_EXECUTE_TOOL_RESULT, e.response.data);
      });
  },
  [Actions.EASY_ASSIGN]: context => {
    const project = context.state.projects.find(
      x => x.id == context.state.selectedProjectId
    );
    if (project != null) {
      Vue.axios
        .post("profile/easyassign", project)
        .then(response => {
          context.commit(Mutations.UPDATE_PROJECT, response.data);
          context.dispatch(Actions.SAVE_CURRENT_PROJECT);
        });
    }
  },
  [Actions.PARSE_MODEL]: (context, parameters) => {
    Vue.axios
      .post("parse/parsemodel", parameters.model)
      .then(response => {
        context.commit(Mutations.UPDATE_MODEL_WITH_PARSE_PARAMETERS_RESULT, {
          projectId: parameters.projectId,
          name: parameters.name,
          parseResult: response.data as ApiModels.ParseResult
        });
        context.dispatch(Actions.SAVE_CURRENT_PROJECT);
        GlobalFunctions.logDebug(response.data);
      });
  },
  [Actions.CREATE_FROM_SWAGGER_URL]: (context, swaggerJsonUrl) => {
    context.commit(Mutations.SET_CURRENT_SWAGGER_URL, swaggerJsonUrl);

    let project = new ApiModels.Project();
    project.name = "Swagger" + Date.now();
    context.commit(Mutations.CREATE_PROJECT, project);

    project = context.getters.getProjectByName(project.name);

    Vue.axios
      .post("parse/parsemodel", {
        modelType: "Swagger Definition",
        parameters: swaggerJsonUrl
      })
      .then(response => {
        context.commit(Mutations.UPDATE_MODEL_WITH_PARSE_PARAMETERS_RESULT, {
          projectId: project.id,
          name: project.name,
          parseResult: response.data as ApiModels.ParseResult
        });

        project = context.getters.getProjectById(project.id);

        Vue.axios
          .post("profile/easyassign", project)
          .then(response => {
            context.commit(Mutations.UPDATE_PROJECT, response.data);
            context.dispatch(Actions.SAVE_CURRENT_PROJECT);
          });
      });
  },
  [Actions.TEST_DEMO_PROJECTS]: (context, parameters) => {
    const updateDemoStatus = (externalId: string, isActive: boolean) => {
      Vue.axios
        .post("profile/setdemostatus/" + externalId + "/" + isActive);
    };

    Vue.axios
      .post("parse/parsemodel", {
        modelType: "Swagger Definition",
        parameters: parameters.url
      })
      .then(response => {
        let project = new ApiModels.Project();
        project.name = "Test" + Date.now();
        context.commit(Mutations.CREATE_PROJECT, project);
        project = context.getters.getProjectByName(project.name);

        context.commit(Mutations.UPDATE_MODEL_WITH_PARSE_PARAMETERS_RESULT, {
          projectId: project.id,
          name: project.name,
          parseResult: response.data as ApiModels.ParseResult
        });

        project = context.getters.getProjectById(project.id);

        Vue.axios
          .post("profile/easyassign", project)
          .then(response => {
            context.commit(Mutations.UPDATE_PROJECT, response.data);
            Vue.axios
              .post("profile/generate", project)
              .then(response => {
                context.commit(Mutations.ADD_TEST_MESSAGES, {
                  success: true,
                  info: parameters.name
                });
                updateDemoStatus(parameters.externalId, true);
              })
              .catch(e => {
                GlobalFunctions.logError(e);
                context.commit(Mutations.ADD_TEST_MESSAGES, {
                  success: false,
                  step: "Generate",
                  info:
                    parameters.name +
                    " Failure. Url=" +
                    parameters.url +
                    ". " +
                    e
                });
                updateDemoStatus(parameters.externalId, false);
              });
          })
          .catch(e => {
            GlobalFunctions.logError(e);
            context.commit(Mutations.ADD_TEST_MESSAGES, {
              success: false,
              step: "Assign",
              info:
                parameters.name + " Failure. Url=" + parameters.url + ". " + e
            });
            updateDemoStatus(parameters.externalId, false);
          })
          .finally();
        {
          context.commit(Mutations.DELETE_PROJECT, project.id);
        }
      })
      .catch(e => {
        GlobalFunctions.logError(e);
        context.commit(Mutations.ADD_TEST_MESSAGES, {
          success: false,
          step: "Parse",
          info: parameters.name + " Failure. Url=" + parameters.url + ". " + e
        });
        updateDemoStatus(parameters.externalId, false);
      });
  }
};

export default new Vuex.Store({
  state: new ApiModels.DataModel(),
  getters: getters,
  mutations: mutations,
  actions: actions,
  modules: {}
});
