import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import axios from "axios";
import VueAxios from "vue-axios";
import VueAnalytics from "vue-analytics";
import { ApiModels } from "./ApiModels/DataModel";
// @ts-ignore
import vueCountryRegionSelect from "vue-country-region-select";
import { Global } from './GlobalFunctions';
import * as Sentry from '@sentry/browser';
import { Vue as VueIntegration } from '@sentry/integrations';

Sentry.init({
  dsn: 'https://3b5d22a01b68454d8ef2345996c28f55@o137465.ingest.sentry.io/304607',
  integrations: [new VueIntegration({Vue, attachProps: true})],
});

Vue.use(Global);

Vue.use(VueAnalytics, {
  id: "UA-71359140-1",
  router
});

Vue.use(vueCountryRegionSelect);

axios.defaults.baseURL = process.env.VUE_APP_API_URL;

axios.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    if (error.response != null && error.response.status == 401) {
      window.localStorage.removeItem("profile");
    }
    Vue.prototype.$global.logDebug(error);
    return Promise.reject(error);
  }
);

const profileString = window.localStorage.getItem("profile");
if (profileString != null) {
  const profile = JSON.parse(profileString as string) as ApiModels.AuthResponse;
  axios.defaults.headers.common["Authorization"] = "Bearer " + profile.token;
}

Vue.use(VueAxios, axios);

Vue.config.productionTip = false;


new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
