


































































import { Component, Prop, Vue } from "vue-property-decorator";
import store from "../../store/index";
import { Actions, Mutations, EventBusActions } from "../../store/StoreActions";
@Component
export default class extends Vue {
  public props = {
    label: "",
    hint: "",
    route: "",
    seo: ""
  };

  private label = "JSON to Query";
  private seo = "JMES path query tool";
  private hint = "JSON to Query";
  private input = "";
  private expression = "";
  private result = "";
  private loading = false;
  private unsubscribe: any;

  submit() {
    this.loading = true;
    this.$store.dispatch(Actions.EXECUTE_JMES_TOOL, {
      input: this.input,
      expression: this.expression
    });
  }

  copy(value: string) {
    this.$global.copyToClipoard(value);
  }

  created() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === Mutations.SET_EXECUTE_TOOL_RESULT) {
        this.result = state.executeToolResult;
        this.loading = false;
      }
    });
  }

  beforeDestroy() {
    this.unsubscribe();
  }
}
