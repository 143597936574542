












import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class extends Vue {

  @Prop()
  public url!: string;

  @Prop()
  public noFloat!:boolean;

  @Prop()
  public square!:boolean;

  getStyle ()
  {
    if(this.noFloat)
    {
      return "width:50px;margin-left:8px;";
    }
    else
    {
        return "float:right;width:50px;margin-left:8px;";
    }
  }

  getIsSquare()
  {
    if(this.square || this.square == null)
    {
      return true;
    }
    else{
      return false;
    }

  }
}
