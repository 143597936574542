import _Vue from 'vue';
import { Component, Prop} from "vue-property-decorator";
import store from "./store/index";
import { Actions, Mutations, EventBusActions } from "./store/StoreActions";
import { ApiModels } from "./ApiModels/DataModel";
import {copyToClipboard} from './BusinessLogic/CopyToClipboard'

/* The way this all works is we augment the Vue instance by declaring a module declare module 'vue/types/vue'.
This will add whatever you put here to 'this' in any component. So this.$global and this $eventBus are added to this.
We're using the event bus in at least one spot to comunicate to the App component to show the snackbac. App subscibes
to the SHOW_SNACKBAR event.

https://stackoverflow.com/questions/43142304/how-to-augment-the-vue-class-and-keep-typescript-definition-in-sync/43232151
has more info on how to extend the Vue instance. Keep in mind you might have to jack with this. The types might work but 
$global is undeinfed in chrome and vice versa.
*/

export function Global(Vue: typeof _Vue): void {
    const global = new GlobalFunctionanlity();
    Vue.prototype.$global = new GlobalFunctionanlity();
    Vue.prototype.$eventBus = EventBus;
    Vue.config.errorHandler = (error) => global.globalErrorHandler;
  }
class GlobalFunctionanlity {

    public eventBus = EventBus;

    public showSnackbar(text:string) : void {
        this.eventBus.$emit(EventBusActions.SHOW_SNACKBAR, text);
    }

    public logDebug(text:string) : void {
        console.log(text);
    }

    public logError(text:string) : void {
        console.log(text);
    }

    public globalErrorHandler(error:any) : void{
        console.log("global error!");
        this.showSnackbar("An error occurred...");
    }

    public copyToClipoard(text:string) : void {
        copyToClipboard(text);
        this.showSnackbar("Copied!");
    }
}


export const EventBus = new _Vue();
export const GlobalFunctions = new GlobalFunctionanlity();


declare module 'vue/types/vue' {
    export interface Vue   {
      $global: GlobalFunctionanlity;
      $eventBus: _Vue;
    }
  }
