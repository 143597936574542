















































































































import { Component, Prop, Vue } from "vue-property-decorator";
import store from "./store/index";
import { Actions, Mutations, EventBusActions } from "./store/StoreActions";
import ToolsMenu from "./components/ToolsMenu.vue";
import { ApiModels } from "./ApiModels/DataModel";
Vue.component("tools-menu", ToolsMenu);

@Component
export default class extends Vue {

  private loading = true;
  private selectedProjectName= "";
  private projects = [];
  private loggedIn = false;
  private profile=new ApiModels.AuthResponse();
  private displaySnackbar= false;
  private snackbarTimeout= 2000;
  private snackbarText= "";
  private unsubscribe:any;

  mounted() {
    store.dispatch(Actions.LOAD_TEMPLATES);
    store.dispatch(Actions.LOAD_JOBS);
    this.loggedIn = store.getters.getIsUserAuthenticated();

    if (this.loggedIn) {
      this.profile = store.getters.getProfile;
      this.$global.logDebug("loading projects...");
      store.dispatch(Actions.LOAD_PROJECTS);
    } else {
      this.$global.logDebug("loading demo...");
      store.dispatch(Actions.LOAD_DEMO);
    }

    this.$eventBus.$on(EventBusActions.SHOW_SNACKBAR, this.showSnackbar);
  }

  showSnackbar(text:string) {
    this.displaySnackbar = true;
    this.snackbarText = text;
  }

  loadProjects() {
    this.projects = this.$store.getters.projects;
  }

  projectMenuSelect(projectId:string) {
    this.$store.commit(Mutations.SET_SELECTIONS, {
      selectedQueryId: "",
      selectedModelId: "",
      selectedProjectId: projectId,
      selectedRouteCollectionId: "",
      setupSelected: false
    });
    this.$router.push("/workspace");
  }

  setSelectedProjectName() {
    const project = this.$store.getters.selectedProject;
    if (project == null) {
      this.selectedProjectName = "Projects";
    } else {
      this.selectedProjectName = project.name;
    }
  }

  created() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (
        mutation.type === Mutations.SET_LOADING_PROJECTS ||
        mutation.type == Mutations.CREATE_PROJECT ||
        mutation.type === Mutations.SET_PROJECTS ||
        mutation.type === Mutations.DELETE_PROJECT ||
        mutation.type === Mutations.UPDATE_PROJECT ||
        mutation.type === Mutations.SET_SELECTIONS
      ) {
        this.loading = this.$store.getters.getLoadingProjects;
        this.loadProjects();
        this.setSelectedProjectName();
      }
    });
  }

  beforeDestroy() {
    this.unsubscribe();
  }
}
