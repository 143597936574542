







































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { Actions, Mutations, EventBusActions } from "../store/StoreActions";
import { ApiModels } from "../ApiModels/DataModel";
import HelpButton from './Shared/HelpButton.vue'
@Component({
    components: {
        'v-help-button': HelpButton
    }
})

export default class extends Vue {
  private showDeleteConfirmation = false;
  private deleteProjectId = "";
  private demoItem = "";
  private demoProjects = [];
  private unsubscribe: any;

  projects() {
    return this.$store.getters.projects;
  }

  editProject(id: string) {
    this.$router.push("/projects/edit/" + id);
  }

  deleteProjectShowDialog(id: string) {
    this.deleteProjectId = id;
    this.showDeleteConfirmation = true;
  }

  getDemoProjects() {
    this.demoProjects = this.$store.state.demoProjects;
  }

  submitCreateFromSwaggerJson() {
    if (this.demoItem == "") {
      this.$global.showSnackbar("Sample definition is required...");
    } else {
      this.$store.dispatch(Actions.CREATE_FROM_SWAGGER_URL, this.demoItem);
      this.$router.push("workspace");
    }
  }

  deleteProject(id: string) {
    this.$store.commit(Mutations.DELETE_PROJECT, id);
    this.$store.dispatch(Actions.SAVE_PROJECT, id);
    this.showDeleteConfirmation = false;
    this.deleteProjectId = "";
  }

  created() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === Mutations.SET_DEMO_PROJECTS) {
        this.getDemoProjects();
      }
    });

    this.$store.dispatch(Actions.LOAD_DEMO);
  }
  
  beforeDestroy() {
    this.unsubscribe();
  }
}
