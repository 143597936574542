









































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import store from "../store/index";
import { Actions, Mutations } from "../store/StoreActions";
import { ApiModels } from "../ApiModels/DataModel";
@Component
export default class extends Vue {
  public props = {
    id: ""
  };

  @Watch("routeCollectionName")
  onParametersChanged(value: string, oldValue: string) {
    this.routeCollectionName = value.replace(" ", "");
    this.baseRoute = "api/" + this.routeCollectionName.toLowerCase();
    if (this.baseRoute.substr(this.baseRoute.length - 1) != "s") {
      this.baseRoute += "s";
    }
  }

  private editMode = false;
  private id = "";
  private baseRoute = "";
  private routeCollectionName = "";
  private rules = {
    required: (value: string) => !!value || "Required."
  };

  mounted() {
    this.loadForm();
    (this.$refs["route"] as any).focus();
  }

  loadForm() {
    if (this.$attrs.id != null) {
      this.id = this.$attrs.id;
      this.editMode = true;
      const existingCollection = this.$store.getters.getRouteCollectionByIdFromSelectedProject(
        this.id
      );
      this.routeCollectionName = existingCollection.name;
      this.baseRoute = existingCollection.baseRoute;
    }
  }

  save() {
    if ((this.$refs.form as any).validate()) {
      if (this.editMode) {
        const existingCollection = this.$store.getters.getRouteCollectionByIdFromSelectedProject(
          this.id
        );
        existingCollection.name = this.routeCollectionName;
        existingCollection.baseRoute = this.baseRoute;
        this.$store.commit(
          Mutations.UPDATE_ROUTE_COLLECTION,
          existingCollection
        );
      } else {
        const collection = new ApiModels.RouteCollection();
        collection.name = this.routeCollectionName;
        collection.baseRoute = this.baseRoute;
        this.$store.commit(Mutations.CREATE_ROUTE_COLLECTION, collection);
      }

      this.$store.dispatch(Actions.SAVE_CURRENT_PROJECT);
      this.$router.push("/routes/create");
    }
  }
}
